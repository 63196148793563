import dayjs from 'dayjs'
import { formatDate, isAppCustomer } from '../../../../common/utils'
import i18n from '../../../../i18n'
import { CustomerDetail } from '../../../../service/api'

export const createExportData = (exportData: CustomerDetail[]) => {
    return exportData.map(customer => {
        const birthDate = customer.birthDate !== undefined
            ? formatDate(dayjs(customer.birthDate), 'DD/MM/YYYY')
            : ''
        const registrationType = isAppCustomer(customer.type)
            ? i18n.t('app')
            : i18n.t('other')
        const registrationDate = customer.registrationDate !== undefined
            ? formatDate(dayjs(customer.registrationDate), 'DD/MM/YYYY')
            : ''
        const profilingAccepted = customer.profilePushAccepted
            ? i18n.t('yes')
            : i18n.t('no')
        return {
            [i18n.t('name')]: customer.firstName,
            [i18n.t('surname')]: customer.lastName,
            [i18n.t('email')]: customer.email,
            [i18n.t('birthDate')]: birthDate,
            [i18n.t('gender')]: customer.gender,
            [i18n.t('city')]: customer.city,
            [i18n.t('phone')]: customer.phone,
            [i18n.t('country')]: customer.country,
            [i18n.t('registrationType')]: registrationType,
            [i18n.t('registrationDate')]: registrationDate,
            [i18n.t('profilingAccepted')]: profilingAccepted,
            [i18n.t('associatedDevices')]: customer.deviceCount
        }
    })
}
