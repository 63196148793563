import { Button, Checkbox, Modal, Row, Table, Tabs, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { TableRowSelection } from 'antd/es/table/interface'
import Title from 'antd/lib/typography/Title'
import dayjs from 'dayjs'
import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as ActionPopupButton } from '../../../../../assets/actionPopupButton.svg'
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrowDown.svg'
import { ReactComponent as CloneIcon } from '../../../../../assets/clone.svg'
import { ReactComponent as AlertIcon } from '../../../../../assets/danger.svg'
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete.svg'
import tabStyles from '../../../../../common/commonCss/Tabs.module.css'
import BackButton from '../../../../../common/components/commonUI/BackButton'
import { ExportMenuItem } from '../../../../../common/components/commonUI/ExportMenuItem'
import CustomSkeleton from '../../../../../common/components/CustomSkeleton'
import PopupMenu from '../../../../../common/components/PopupMenu'
import { formatDate, isAppCustomer } from '../../../../../common/utils'
import { CustomerApiFactory, CustomerDetail, CustomerList, DeleteListCustomersRequest } from '../../../../../service/api'
import { CreateListModal } from './CreateListModal'
import styles from './ListDetail.module.css'
import { createListExportData } from './ListDetailUtils'

enum TabKey {
    All = 'All',
    App = 'App',
    Crm = 'Crm'
}

interface ListDetailProps {
    dataSource: ListCustomerModel[]
    selectedKeys: React.Key[]
    onSelect: (selectedRows: React.Key[]) => void
}
export const ListDetailTable = (props: ListDetailProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(props.selectedKeys)
    const navigate = useNavigate()
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
        props.onSelect(newSelectedRowKeys)
    }

    const rowSelection: TableRowSelection<ListCustomerModel> = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const columns: ColumnsType<ListCustomerModel> = [
        {
            title: t('name'),
            dataIndex: 'fullName',
            sorter: {
                compare: (a, b) => {
                    if (a.fullName && b.fullName) {
                        return a.fullName.localeCompare(b.fullName)
                    } else if (a.fullName) {
                        return -1
                    } else if (b.fullName) {
                        return 1
                    }
                    return 0
                }
            },
        },
        {
            title: t('email'),
            dataIndex: 'email',
            sorter: {
                compare: (a, b) => {
                    return a.email.localeCompare(b.email)
                }
            },
        },
        {
            title: t('location'),
            dataIndex: 'city',
            sorter: {
                compare: (a, b) => {
                    if (a.city && b.city) {
                        return a.city.localeCompare(b.city)
                    } else if (a.city) {
                        return -1
                    } else if (b.city) {
                        return 1
                    }
                    return 0
                }
            },
        },
        {
            title: t('registration'),
            dataIndex: 'registrationDate',
            render: (value) => <>{formatDate(dayjs(value), 'DD MMM YYYY HH:mm')}</>,
            sorter: {
                compare: (a, b) => {
                    if (a.registrationDate && b.registrationDate) {
                        return a.registrationDate.valueOf() - b.registrationDate.valueOf()
                    } else if (a.city) {
                        return -1
                    } else if (b.city) {
                        return 1
                    }
                    return 0
                }
            },
        },
        {
            title: t('phone'),
            dataIndex: 'phone',
            sorter: {
                compare: (a, b) => {
                    if (a.phone && b.phone) {
                        return a.phone.localeCompare(b.phone)
                    } else if (a.phone) {
                        return -1
                    } else if (b.phone) {
                        return 1
                    }
                    return 0
                }
            },
        },
    ]
    return (
        <>
            <Table
                scroll={{ x: 400 }}
                rowKey={record => record.id}
                dataSource={props.dataSource}
                columns={columns}
                className={'tableView'}
                showSorterTooltip={false}
                rowSelection={rowSelection}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            const customerDetail: CustomerDetail = {
                                pk: record.id,
                                email: record.email,
                                type: record.type,
                            }
                            const params = {
                                selectedContact: customerDetail
                            }
                            navigate('../contacts/detail', { state: params })
                        }
                    }
                }}
                pagination={{
                    position: ['bottomCenter'],
                    hideOnSinglePage: props.dataSource ? props.dataSource.length <= 10 : undefined,
                    showSizeChanger: true,
                    style: {
                        marginTop: '45px'
                    },
                    responsive: true,
                    showTitle: false
                }}
            />
        </>
    )
}

export interface ListCustomerModel {
    id: number
    email: string
    phone: string
    city: string
    fullName: string
    registrationDate: number
    type: number
}
export const ListDetail = (): ReactElement => {
    const [t] = useTranslation('translations')
    const api = CustomerApiFactory()
    const location = useLocation()
    const selectedList: CustomerList = (location.state as CustomerList)
    const navigate = useNavigate()

    const [selectedRows, setSelectedRows] = useState<React.Key[]>([])

    const [loading, setLoading] = useState<boolean>(false)
    const [contactsInList, setContactsInList] = useState<ListCustomerModel[]>([])
    const [filteredContacts, setFilteredContacts] = useState<ListCustomerModel[]>([])
    const [popupVisible, setPopupVisible] = useState<boolean>(false)

    const [customerModalOpen, setCustomerModalOpen] = useState<boolean>(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)

    const [cloneListModalOpen, setCloneListModalOpen] = useState<{ open: boolean, list: CustomerList | undefined }>({ open: false, list: undefined })

    const [removeFromCrm, setRemoveFromCrm] = useState<boolean>(false)

    const onTabChange = (key: string) => {
        switch (key) {
        case TabKey.All: setFilteredContacts(contactsInList)
            break
        case TabKey.App: setFilteredContacts(contactsInList.filter(contact => isAppCustomer(contact.type)))
            break
        case TabKey.Crm: setFilteredContacts(contactsInList.filter(contact => !isAppCustomer(contact.type)))
            break
        }
    }
    const menuContent = [
        {

            key: 1,
            label: <>
                <ExportMenuItem
                    filename={selectedList.name}
                    disabled={selectedRows.length === 0}
                    dataSource={createListExportData(filteredContacts.filter(contact => selectedRows.map(key => key.valueOf()).includes(contact.id)))}
                >
                    <span className='ant-menu-title-content'>{t('exportSelected')}</span>
                </ExportMenuItem>
            </>,
            disabled: selectedRows.length === 0,
            onClick: () => {
                setPopupVisible(false)
            }
        },
        {

            key: 2,
            label: <>{t('deleteSelected')}</>,
            disabled: selectedRows.length === 0,
            onClick: () => {
                setPopupVisible(false)
                setCustomerModalOpen(true)
            }
        }
    ]

    const updateData = () => {
        setLoading(true)
        setFilteredContacts([])
        setIsError(false)
        setSelectedRows([])
        api.getListCustomers(selectedList.pk).then(response => {
            const contacts = response.data as ListCustomerModel[]
            setContactsInList(contacts)
            setFilteredContacts(contacts)
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        updateData()
    }, [])

    const handleDeleteList = () => {
        setIsDeleting(true)
        api.deleteList(selectedList.pk, removeFromCrm).then(response => {
            navigate(-1)
        }).catch(error => {
            setDeleteModalOpen(false)
            setIsError(true)
        }).finally(() => setIsDeleting(false))
    }

    const handleDeleteCustomers = () => {
        setIsDeleting(true)
        const customers = selectedRows.map(row => ({
            id: Number(row.valueOf())
        }))
        api.deleteListCustomers(selectedList.pk, removeFromCrm, customers as DeleteListCustomersRequest).then(() => {
            updateData()
        }).catch(() => {
            setIsError(true)
        }).finally(() => {
            setCustomerModalOpen(false)
            setIsDeleting(false)
        })
    }
    return (
        <>
            <div className={styles.container}>
                <Row className={styles.titleRow}>
                    <div className={styles.titleContainer}>
                        <Title level={3} className={styles.title}>{selectedList.name}</Title>
                        {selectedList.customerCount !== undefined &&
                            <span className={styles.itemsCount}>
                                &nbsp;&nbsp;&bull;&nbsp; {selectedList.customerCount} {t('contacts')}
                            </span>
                        }
                        <Tag className={styles.tag}>{t('list')}</Tag>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            onClick={() => setDeleteModalOpen(true)}
                            icon={<DeleteIcon className={styles.deleteIcon} />}
                            className={`blackFillPositiveButton ${styles.deleteButton}`}>
                            {t('deleteList')}
                        </Button>
                        <Button
                            onClick={() => setCloneListModalOpen({ open: true, list: selectedList })}
                            icon={<CloneIcon className={styles.cloneIcon} />}
                            className={`yellowFillPositiveButton ${styles.cloneButton}`}>
                            {t('cloneList')}
                        </Button>
                        <PopupMenu
                            content={[
                                {
                                    key: '1',
                                    disabled: contactsInList.length === 0,
                                    label: <>
                                        <ExportMenuItem
                                            filename={selectedList.name}
                                            disabled={contactsInList.length === 0}
                                            dataSource={createListExportData(contactsInList)}
                                        >
                                            <span className='ant-menu-title-content'>{t('exportAll')}</span>
                                        </ExportMenuItem>
                                    </>,
                                },
                                {
                                    key: '2',
                                    label: t('importContacts'),
                                    onClick: () => navigate('../contacts/import', { replace: true })
                                }
                            ]}
                            popupVisible={popupVisible}
                            placement='bottomRight'>
                            <Button
                                icon={<ActionPopupButton />}
                                shape='circle'
                                className={styles.popupButton}
                            />
                        </PopupMenu>
                    </div>
                </Row>
                <BackButton />
                <div className={styles.tabsRow}>
                    <Tabs
                        className={tabStyles.tabs}
                        defaultActiveKey={TabKey.All}
                        items={[
                            { key: TabKey.All, label: t('all') },
                            { key: TabKey.App, label: t('appUsers') },
                            { key: TabKey.Crm, label: t('contactCrm') }
                        ]}
                        onChange={onTabChange} />

                    <PopupMenu popupVisible={popupVisible} content={menuContent} >
                        <Button
                            className={styles.bulkButton}
                            icon={<ArrowIcon />}
                            iconPosition='end'>{t('bulkActions')}</Button>
                    </PopupMenu>
                </div>
                {loading ?
                    <CustomSkeleton height={400} />
                    :
                    <ListDetailTable
                        selectedKeys={selectedRows}
                        onSelect={keys => setSelectedRows(keys)}
                        dataSource={filteredContacts} />
                }
                <CreateListModal
                    openSettings={cloneListModalOpen}
                    onSave={(response) => {
                        console.log(response)
                        setCloneListModalOpen({ open: false, list: undefined })
                        navigate('../lists/detail', { state: response, replace: true })
                    }}
                    onCancel={() => setCloneListModalOpen({ open: false, list: undefined })}
                />
                <Modal
                    closable={false}
                    className={'modal'}
                    open={customerModalOpen}
                    footer={
                        <>
                            <Button
                                disabled={isDeleting}
                                onClick={() => setCustomerModalOpen(false)}
                                className={'yellowOutlinedCancelButton'}>
                                {t('cancel')}
                            </Button>
                            <Button
                                loading={isDeleting}
                                className={'blackFillPositiveButton'}
                                onClick={() => handleDeleteCustomers()}>
                                {t('delete')}
                            </Button>
                        </>
                    }>
                    <AlertIcon ></AlertIcon>
                    <p>
                        <Trans>{t('deleteCustomerQuestion', { count: selectedRows.length })}</Trans>
                    </p>
                    <p>
                        <Checkbox checked={removeFromCrm} className={styles.checkbox} onChange={(event) => setRemoveFromCrm(event.target.checked)}>{t('removeCutomersFromCrm')}</Checkbox>
                    </p>
                </Modal>
                <Modal
                    closable={false}
                    className={'modal'}
                    open={deleteModalOpen}
                    footer={
                        <>
                            <Button
                                disabled={isDeleting}
                                onClick={() => setDeleteModalOpen(false)}
                                className={'yellowOutlinedCancelButton'}>
                                {t('cancel')}
                            </Button>
                            <Button
                                loading={isDeleting}
                                className={'blackFillPositiveButton'}
                                onClick={() => handleDeleteList()}>
                                {t('delete')}
                            </Button>
                        </>
                    }>
                    <AlertIcon ></AlertIcon>
                    <p>
                        <Trans>{t('deleteListQuestion', { count: 1 })}</Trans>
                    </p>
                    <p>
                        <Checkbox checked={removeFromCrm} className={styles.checkbox} onChange={(event) => setRemoveFromCrm(event.target.checked)}>{t('removeCutomersFromCrm')}</Checkbox>
                    </p>
                </Modal>
                <Modal
                    closable={false}
                    className={'modal'}
                    open={isError}
                    footer={
                        <>
                            <Button className={'blackFillPositiveButton'} onClick={() => setIsError(false)}>
                                OK
                            </Button>
                        </>
                    }>
                    <AlertIcon></AlertIcon>
                    <p>{t('somethingWentWrong')}</p>
                </Modal>
            </div>
        </>
    )
}