import { Button, Divider, Form, Input, Modal, SelectProps, notification } from 'antd'
import Title from 'antd/es/typography/Title'
import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as AlertIcon } from '../../../../assets/alert.svg'
import { ReactComponent as DangerIcon } from '../../../../assets/danger.svg'
import BackButton from '../../../../common/components/commonUI/BackButton'
import { CustomizeRequiredMark } from '../../../../common/components/commonUI/CustomRequiredMark'
import { Image, Product, ProductVariant, ProductsApiFactory } from '../../../../service/api'
import { OperationStatus } from '../../../users/AdminUtils'
import MultipleImageUploader from './ImageUploader/MultipleImageUploader'
import Editor from './TextEditor/Editor'
import CollapsibleList, { VariantPayload } from './VariantForm/CollpasibleList'
import styles from './productDetail.module.css'

const options: SelectProps['options'] = []

export enum ProductType {
    Published = 'Published',
    Draft = 'Draft',
    Trash = 'Trash'
}

interface ProductParam {
    selectedProduct: Product
}

interface VariantRequest {
    variantsToDelete: number[]
    variantsToUpdate: VariantPayload[]
    variantsToCreate: VariantPayload[]
}


const ProductDetail = (): ReactElement => {
    const productApi = ProductsApiFactory()
    const [t] = useTranslation('translations')
    const [productForm] = Form.useForm()
    const location = useLocation()
    const navigate = useNavigate()

    const [creationResults, setCreationResults] = useState<OperationStatus>({ operationErrors: [], operationSuccessful: [] })
    const [deletionResults, setDeletionResults] = useState<OperationStatus>({ operationErrors: [], operationSuccessful: [] })
    const [updateResults, setUpdateResults] = useState<OperationStatus>({ operationErrors: [], operationSuccessful: [] })
    const [variantRequests, setVariantRequests] = useState<VariantRequest>({ variantsToDelete: [], variantsToCreate: [], variantsToUpdate: [] })
    const [isPublished, setIsPublished] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const selectedProduct = location.state ? (location.state as ProductParam).selectedProduct : undefined

    const [variantsPayloadList, setVariantsPayloadList] = useState<VariantPayload[]>([])
    const [variantsErrors, setVariantsErrors] = useState<ProductVariant[]>([])
    const [variants, setVariants] = useState<ProductVariant[]>([])

    const [files, setFiles] = useState<File[]>([])
    const [images, setImages] = useState<Image[]>([])
    const [product, setProduct] = useState<Product>(selectedProduct ?? {
        pk: 0,
        coverImage: { id: null, imageUrl: null },
        longDescription: '',
        shortDescription: '',
        images: [],
        variantsCount: 0,
        title: ''
    })

    useEffect(() => {
        if (selectedProduct !== undefined) {
            const imageList: Image[] = [selectedProduct.coverImage]
            if (selectedProduct.images) {
                selectedProduct.images.forEach(image => {
                    imageList.push(image)
                })
            }
            setImages(imageList)
            setProduct(selectedProduct)
            setProduct(prevState => ({ ...prevState, images: imageList }))
            setVariants([])
            productApi.getAllVariants(product.pk).then(response => {
                setVariants(response.data)
            })
        }
    }, [selectedProduct])

    const handleVariantChange = (variantList: VariantPayload[]) => {
        setVariantsPayloadList(variantList)
    }

    const handleFinish = (values: any) => {
        const variantsErrors: ProductVariant[] = []
        variantsPayloadList.forEach(variantPayload => {
            if (!variantPayload.validation) {
                variantsErrors.push(variantPayload.variant)
            }
        })
        setVariantsErrors(variantsErrors)
        if (variantsErrors.length === 0) {
            handleProductApiCall()
        }
    }

    const handleProductApiCall = () => {
        setIsLoading(true)
        selectedProduct ?
            productApi.updateProduct(product.pk, files, product).then(response => {
                if (variantsPayloadList.length > 0 || variantsPayloadList.length !== product.variantsCount) {
                    handleVariantApiCall(response.data.pk)
                } else {
                    setIsPublished(true)
                }
            }).catch(error => {
                notification.error({
                    message: t('error'),
                    description: t('somethingWentWrong'),
                })
            }).finally(() => setIsLoading(false))
            :
            productApi.addProduct(product, files).then(response => {
                if (variantsPayloadList.length > 0 || variantsPayloadList.length !== product.variantsCount) {
                    handleVariantApiCall(response.data.pk)
                } else {
                    setIsPublished(true)
                }
            }).catch(error => {
                notification.error({
                    message: t('error'),
                    description: t('somethingWentWrong'),
                })
            }).finally(() => setIsLoading(false))
    }

    const handleVariantApiCall = (productPk: number) => {
        const variantRequest: VariantRequest = checkVariants()
        if (variantRequest.variantsToCreate.length > 0) {
            variantRequest.variantsToCreate.forEach((variantPayload, index) => {
                productApi.addVariant(productPk, variantPayload.variant, (variantPayload.file?.originFileObj as File)).then(response => {
                    setCreationResults(prevState => (
                        {
                            operationErrors: prevState.operationErrors, operationSuccessful: [...prevState.operationSuccessful, response.data.pk!]
                        })
                    )
                }).catch(error => {
                    creationResults.operationErrors.push({ pk: productPk, error: error.name })
                    notification.error({
                        message: t('error'),
                        description: `${t('somethingWentWrongForVariant')} ${variantPayload.variant.name}`,
                    })
                })
            })
        }
        if (variantRequest.variantsToUpdate.length > 0) {
            variantRequest.variantsToUpdate.forEach(variantPayload => {
                productApi.updateVariant(productPk, variantPayload.variant.pk!, variantPayload.variant, variantPayload.file?.originFileObj as File ?? undefined).then(response => {
                    setUpdateResults(prevState => (
                        {
                            operationErrors: prevState.operationErrors, operationSuccessful: [...prevState.operationSuccessful, variantPayload.variant.pk!]
                        })
                    )
                }).catch(error => {
                    updateResults.operationErrors.push({ pk: productPk, error: error.name })
                    notification.error({
                        message: t('error'),
                        description: `${t('somethingWentWrongForVariant')} ${variantPayload.variant.name}`,
                    })
                })
            })
        }
        if (variantRequest.variantsToDelete.length > 0) {
            variantRequest.variantsToDelete.forEach(variantPk => {
                productApi.deleteProductVariant(productPk, variantPk).then(response => {
                    setDeletionResults(prevState => (
                        {
                            operationErrors: prevState.operationErrors, operationSuccessful: [...prevState.operationSuccessful, variantPk]
                        })
                    )
                }).catch(error => {
                    deletionResults.operationErrors.push({ pk: productPk, error: error.name })
                    notification.error({
                        message: t('error'),
                        description: `${t('somethingWentWrong')}`,
                    })
                })
            })
        }
    }

    const checkVariants = (): VariantRequest => {
        const toDelete: number[] = []
        const toUpdate: VariantPayload[] = []
        const toCreate: VariantPayload[] = []
        variantsPayloadList.forEach(variantPayload => {
            const variant: ProductVariant | undefined = variants.find(variant => variant.pk === variantPayload.variant.pk)
            if (variant) {
                toUpdate.push({
                    file: variantPayload.file,
                    variant: variantPayload.variant,
                    validation: variantPayload.validation
                })
            } else {
                toCreate.push({
                    file: variantPayload.file,
                    variant: variantPayload.variant,
                    validation: variantPayload.validation
                })
            }
        })
        variants.filter(v => {
            const variant = variantsPayloadList.find(variantPayload => variantPayload.variant.pk === v.pk)
            if (variant === undefined) {
                toDelete.push(v.pk!)
            }
        })
        const variantRequest = { variantsToDelete: toDelete, variantsToCreate: toCreate, variantsToUpdate: toUpdate }
        setVariantRequests(variantRequest)
        return variantRequest
    }

    const handleEditorChange = (value: string) => {
        setProduct(prevState => ({ ...prevState, longDescription: value }))
    }

    useEffect(() => {
        if (variantRequests.variantsToCreate.length > 0 || variantRequests.variantsToDelete.length > 0 || variantRequests.variantsToUpdate.length > 0) {
            if ((variantRequests.variantsToCreate.length === creationResults.operationSuccessful.length)
                && (variantRequests.variantsToDelete.length === deletionResults.operationSuccessful.length)
                && (variantRequests.variantsToUpdate.length === updateResults.operationSuccessful.length)) {
                setIsPublished(true)
            }
        }
    }, [creationResults, updateResults, deletionResults])
    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <Title className={styles.title}>{product ? product.title : t('productTitle')}</Title>
                <div className={styles.buttonsContainer}>
                    <Button
                        onClick={() => {
                            const imagesList: Image[] = JSON.parse(JSON.stringify(images))
                            product.coverImage = imagesList.shift()!
                            if (imagesList.length) {
                                product.images = imagesList.length > 0 ? imagesList : []
                                productForm.setFieldValue('images', imagesList)
                            } else {
                                product.images = []
                                productForm.setFieldValue('images', product.coverImage)
                            }
                            productForm.submit()
                        }}
                        loading={isLoading}
                        className={`yellowFillPositiveButton ${styles.publishButton} ${styles.commonButton}`}>
                        {t('publish')}
                    </Button>
                </div>
            </div>
            <BackButton />
            <Form
                initialValues={({
                    title: product.title,
                    shortDescription: product.shortDescription,
                    longDescription: product.longDescription,
                    images: (product.coverImage && product.coverImage.imageUrl) ? product.coverImage.imageUrl : ''
                })}
                onFinish={handleFinish}
                className={styles.form}
                style={{ marginTop: '10px' }}
                form={productForm}
                requiredMark={CustomizeRequiredMark}
                layout="vertical">
                <div className={styles.formContainer}>
                    <Form.Item
                        label={t('productTitle')}
                        name={'title'}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}>
                        <Input
                            onChange={event => setProduct(prevState => ({ ...prevState, title: event.target.value }))}
                            value={product.title}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                        name={'shortDescription'}
                        label={t('subtitle')}>
                        <Input
                            onChange={event => setProduct(prevState => ({ ...prevState, shortDescription: event.target.value }))}
                            value={product.shortDescription} />
                    </Form.Item>
                    <Form.Item
                        name={'longDescription'}
                        label={t('description')}
                        style={{ height: '250px' }}
                        rules={[
                            {
                                required: true,
                                message: t('writeDescription')
                            }
                        ]}>
                        <Editor
                            onChange={handleEditorChange}
                            toolbarClassName='detailEditor'
                            description={product.longDescription ?? ''} />
                    </Form.Item>
                </div>
                <div className={styles.formContainer}>
                    <Title className={styles.formTitle}>{t('images')}</Title>
                    <Divider></Divider>
                    <Form.Item
                        name={'images'}
                        rules={[
                            {
                                required: true,
                                message: t('uploadImage')
                            }
                        ]}
                        className={styles.form}
                        style={{ marginTop: '10px' }}>
                        <MultipleImageUploader
                            onLoad={(fileList) => {
                                setFiles([])
                                setImages([])
                                fileList.map(file => {
                                    if (file.originFileObj) {
                                        setImages(prevState => [...prevState, { id: null, imageUrl: null }])
                                        setFiles(prevState => [...prevState, file.originFileObj as File])
                                    } else {
                                        setImages(prevState => [...prevState, { id: file.uid, imageUrl: file.url! }])
                                    }
                                })
                            }}
                            productImages={product.images} />
                    </Form.Item>
                </div>
            </Form>
            <div className={styles.formContainer}>
                <div
                    className={styles.form}
                    style={{ marginTop: '10px' }} >
                    <Title className={styles.formTitle}>{t('variants')}</Title>
                    <Divider></Divider>
                    <div className={styles.variantContainer}>
                        <CollapsibleList
                            productPk={product.pk ?? undefined}
                            onChange={handleVariantChange}
                            variants={variants} />
                    </div>
                </div>
            </div>
            <Modal
                closable={false}
                className={'modal'}
                open={isPublished}
                footer={
                    <>
                        <Button
                            className={'blackFillPositiveButton'}
                            onClick={() => navigate(-1)}>
                            {'OK'}
                        </Button>
                    </>
                }>
                <AlertIcon />
                <p><Trans>{t('productCreatedSuccesfully', { name: product.title })}</Trans></p>
            </Modal>
            <Modal
                closable={false}
                className={'modal'}
                open={variantsErrors.length > 0}
                footer={
                    <>
                        <Button
                            className={'blackFillPositiveButton'}
                            onClick={() => setVariantsErrors([])}>
                            {'OK'}
                        </Button>
                    </>
                }>
                <DangerIcon />
                <div>{t('variantsNotComplete')}</div>
                <ul className={styles.modalErrorList}>{variantsErrors.map(error => {
                    return (<li style={{ fontWeight: 'bold' }} key={error.pk}>{error.name && error.name !== '' ? error.name : t('emptyName')}</li>)
                })}
                </ul>
            </Modal>
        </div >
    )
}

export default ProductDetail
