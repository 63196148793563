import Icon from '@ant-design/icons'
import { Empty, Table } from 'antd'
import { TableRowSelection } from 'antd/es/table/interface'
import type { ColumnsType } from 'antd/lib/table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as NoDataIcon } from '../../../assets/cloud-cross.svg'
import { ReactComponent as ContactAppIcon } from '../../../assets/userPlaceHolder.svg'
import { Colors } from '../../../common/Colors'
import '../../../common/commonCss/tableView.css'
import CustomSkeleton from '../../../common/components/CustomSkeleton'
import { User } from '../../../service/api'
import { getRoleTranslation } from './UserDetailForm/UserDetailForm'

interface PropsType {
    loaded: boolean
    data: User[]
    onSelect: (newSelectedRowKeys: React.Key[]) => void
    onRowClick: (seletedItem: User) => void
}

export default function UserTable(props: PropsType) {

    const [t] = useTranslation('translations')
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
        props.onSelect(newSelectedRowKeys)
    }
    const rowSelection: TableRowSelection<User> = {
        selectedRowKeys,
        onChange: onSelectChange,
    }
    const columns: ColumnsType<User> = [
        {
            title: t('username'),
            dataIndex: 'username',
            sorter: {
                compare: (a, b) => a.username.localeCompare(b.username)
            },
            render: (productName: string, record: User) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon
                        component={ContactAppIcon}
                        style={{ fontSize: '28px', marginRight: 14 }} />
                    {productName}
                </span>
            )
        },
        {
            title: t('email'),
            dataIndex: 'email',
            sorter: {
                compare: (a, b) => a.email.localeCompare(b.email)
            }
        },
        {
            title: t('name'),
            dataIndex: 'firstName',
            sorter: {
                compare: (a, b) => {
                    if (a.firstName && b.firstName) {
                        return a.firstName.localeCompare(b.firstName)
                    }
                    if (a.firstName) {
                        return -1
                    }
                    if (b.firstName) {
                        return 1
                    }
                    return 0
                }
            },
            render: (firstName: string, record: User) => (
                <span>
                    {record.firstName} {record.lastName}
                </span>
            )
        },
        {
            title: t('organizations'),
            dataIndex: 'organizations',
            sorter: {
                compare: (a, b) => {
                    if ((a.organizations && a.organizations.length) && (b.organizations && b.organizations.length > 0)) {
                        a.organizations![0].name.localeCompare(b.organizations![0].name)
                    } else if (a.organizations && a.organizations.length > 0) {
                        return -1
                    } else if (b.organizations && b.organizations.length > 0) {
                        return 1
                    }
                    return 0
                }
            },
            render: (organizations: string, record: User) => (
                <span>
                    {(record.organizations && record.organizations.length > 0) ? record.organizations[0].name : ''} <p style={{ fontWeight: 700, display: 'inline' }}>{record.organizations!.length > 1 && `+${record.organizations!.length - 1}`}</p>
                </span>
            )
        },
        {
            title: t('role'),
            dataIndex: 'role',
            sorter: {
                compare: (a, b) => a.role.localeCompare(b.role)
            },
            render: (role: string, record: User) => {
                return <span>{getRoleTranslation(role)}</span>
            }
        },
    ]
    return (
        <div >
            {!props.loaded ?
                <div style={{ paddingBottom: '160px' }}>
                    <CustomSkeleton height={300} />
                </div>
                :
                <>
                    <Table
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    props.onRowClick(record)
                                }
                            }
                        }}
                        rowKey={(record) => record.pk!}
                        rowSelection={rowSelection}
                        dataSource={props.data}
                        columns={columns}
                        className={'tableView'}
                        showSorterTooltip={false}
                        pagination={{
                            position: ['bottomCenter'],
                            hideOnSinglePage: props.data.length <= 10,
                            showSizeChanger: true,
                            style: {
                                marginTop: '45px'
                            },
                            responsive: true,
                            showTitle: false
                        }}
                    />
                    {props.data.length === 0 &&
                        <Empty
                            image={<Icon component={NoDataIcon} />}
                            imageStyle={{ fontSize: '60px', marginTop: '50px', marginBottom: '-8px' }}
                            style={{ marginTop: '50px', paddingBottom: '180px' }}
                            description={(
                                <span style={{ color: Colors.black }}>{t('noData')}</span>
                            )} />
                    }
                </>
            }
        </div>
    )
}

