import { Avatar, Checkbox, DatePicker, Form, FormInstance, Input, Select } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import { ReactComponent as IconPlaceHolder } from '../../../../../assets/userPlaceHolder.svg'
import { CustomizeRequiredMark } from '../../../../../common/components/commonUI/CustomRequiredMark'
import CustomSkeleton from '../../../../../common/components/CustomSkeleton'
import { useMobileMediaQuery } from '../../../../../hooks/useMobileMediaQuery'
import i18n from '../../../../../i18n'
import { CustomerDetail } from '../../../../../service/api'
import styles from './ContactForm.module.css'
import { Gender, getGenderTranslation } from './ContactUtils'

interface MetadataMapping {
    key: string,
    value: [string] | null
    mandatory: boolean
}

interface ContactFormProps {
    selectedContact?: CustomerDetail
    disabled: boolean
    form: FormInstance<any>
    onFinish: (values: any, enabled: boolean) => void
}

export const ContactForm = (props: ContactFormProps) => {
    const isMobile = useMobileMediaQuery()
    const [t] = useTranslation('translations')
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [enabledContact, setEnabledContact] = useState<boolean>(true)
    const [dynamicFields, setDynamicFields] = useState<MetadataMapping[]>()
    const currentUserState = useSelector((state: RootState) => state.currentUserState)

    useEffect(() => {
        if (currentUserState.user.pk !== -1) {
            const setting = currentUserState.user.application.settings.filter(setting => setting.key === 'customerMetadataMapping')[0]
            setDynamicFields(JSON.parse(setting.value))
        }
    }, [currentUserState])

    useEffect(() => {
        if (dynamicFields && dynamicFields.length > 0) {
            dynamicFields.map(field => {
                const value: string | undefined = (props.selectedContact && props.selectedContact.metadata) ? props.selectedContact.metadata[field.key] : undefined
                props.form.setFieldValue(`${field.key}`, value)
            })
        }
    }, [dynamicFields])
    useEffect(() => {
        setIsDisabled(props.disabled!)
    }, [props.disabled])

    useEffect(() => {
        setEnabledContact((props.selectedContact !== undefined && props.selectedContact.enabled !== undefined) ? props.selectedContact.enabled : true)
        props.form.setFieldValue('notes', props.selectedContact?.notes)
        props.form.setFieldValue('birthDate', props.selectedContact?.birthDate ? dayjs(props.selectedContact?.birthDate) : null)
        if (dynamicFields) {
            dynamicFields.map(field => {
                const label = field.key
                const value: string | undefined = (props.selectedContact && props.selectedContact.metadata) ? props.selectedContact.metadata[label] : undefined
                props.form.setFieldValue(`${field.key}`, value)
            })
        }
    }, [props.selectedContact, dynamicFields])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.formContainer}>
                    {dynamicFields !== undefined ?
                        <>
                            <div className={styles.accountImage}>
                                <Avatar
                                    src={props.selectedContact?.pictureUrl}
                                    icon={<IconPlaceHolder className={styles.userImage}></IconPlaceHolder>}
                                    shape='circle'
                                    size={140} />
                                <Checkbox
                                    disabled={isDisabled}
                                    checked={!enabledContact}
                                    onChange={e => {
                                        setEnabledContact(!e.target.checked)
                                    }} className={styles.contactCheckbox}>{t('disableUser')}</Checkbox>
                            </div>
                            <div className={styles.userInfo}>
                                <Form
                                    initialValues={props.selectedContact ?
                                        {
                                            'email': props.selectedContact.email,
                                            'firstName': props.selectedContact.firstName,
                                            'lastName': props.selectedContact.lastName,
                                            'birthdate': dayjs(props.selectedContact.birthDate),
                                            'gender': props.selectedContact.gender,
                                            'phone': props.selectedContact.phone,
                                            'zip': props.selectedContact.zip,
                                            'address': props.selectedContact.address,
                                            'city': props.selectedContact.city,
                                            'province': props.selectedContact.province,
                                            'country': props.selectedContact.country,
                                            'notes': props.selectedContact.notes,
                                            'metadata': null
                                        }
                                        : undefined}
                                    form={props.form}
                                    onFinish={(values) => {
                                        const obj = {}
                                        dynamicFields.map(field => {
                                            Object.defineProperty(obj, field.key, {
                                                enumerable: true,
                                                value: props.form.getFieldValue(field.key)
                                            })
                                        })
                                        values['metadata'] = obj
                                        props.onFinish(values, enabledContact)
                                    }}
                                    requiredMark={CustomizeRequiredMark}
                                    className={`${isDisabled ? styles.formDisabled : styles.formEnabled} ${styles.form}`}
                                    layout='vertical' >
                                    <Form.Item
                                        name={'email'}
                                        label={t('email')}
                                        rules={[
                                            {
                                                required: true,
                                                type: 'email',
                                                whitespace: true,
                                                message: t('emailWrong')
                                            },
                                        ]}
                                    >
                                        <Input disabled={isDisabled} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'firstName'}
                                        label={t('name')}>
                                        <Input disabled={isDisabled} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'lastName'}
                                        label={t('lastName')}>
                                        <Input disabled={isDisabled} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'birthDate'}
                                        label={t('birthDate')}
                                    >
                                        <DatePicker
                                            placeholder=''
                                            className={isDisabled ? styles.datePickerDisabled : styles.datePickerEnabled}
                                            disabled={isDisabled} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'gender'}
                                        label={t('gender')}
                                    >
                                        <Select
                                            options={Object.values(Gender).map(gender => ({
                                                label: getGenderTranslation(gender),
                                                value: gender
                                            }))}
                                            className={`${styles.selector} ${isDisabled && styles.selectorDisabled}`}
                                            disabled={isDisabled} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'phone'}
                                        label={t('phone')}
                                    >
                                        <Input disabled={isDisabled} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'zip'}
                                        label={t('zipCode')}
                                    >
                                        <Input disabled={isDisabled} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'address'}
                                        label={t('address')}
                                    >
                                        <Input disabled={isDisabled} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'city'}
                                        label={t('city')}
                                    >
                                        <Input disabled={isDisabled} />
                                    </Form.Item>
                                    <Form.Item
                                        normalize={(value: string) => value.toUpperCase()}
                                        name={'province'}
                                        label={t('province')}
                                    >
                                        <Input
                                            maxLength={2}
                                            disabled={isDisabled} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'country'}
                                        label={t('country')}
                                    >
                                        <Input disabled={isDisabled} />
                                    </Form.Item>
                                    {dynamicFields.map(field => {
                                        return field.value !== null ?
                                            <>
                                                <Form.Item
                                                    name={field.key}
                                                    label={i18n.t('' + field.key)}
                                                >
                                                    <Select

                                                        className={`${styles.selector} ${isDisabled && styles.selectorDisabled}`}
                                                        options={field.value.map(value => ({
                                                            label: value,
                                                            value: value
                                                        }))}
                                                        disabled={isDisabled} />
                                                </Form.Item>
                                            </>
                                            :
                                            <>
                                                <Form.Item
                                                    name={field.key}
                                                    label={i18n.t('' + field.key)}
                                                >
                                                    <Input disabled={isDisabled} />
                                                </Form.Item>
                                            </>
                                    })}
                                    <div className={styles.noteContainer}>
                                        <Form.Item
                                            tooltip={t('internalNote')}
                                            name={'notes'}
                                            label={t('notes')}
                                        >
                                            <Input.TextArea
                                                className={styles.textArea}
                                                disabled={isDisabled} />
                                        </Form.Item>
                                    </div>
                                </Form >
                            </div >
                        </>
                        :
                        <>
                            <CustomSkeleton height={500} />
                        </>
                    }
                </div>
            </div >
        </>
    )

}