import { Empty, Table } from 'antd'
import Search from 'antd/lib/input/Search'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from '../../../../../../app/store'
import { ReactComponent as NoDataIcon } from '../../../../../../assets/cloud-cross.svg'
import searchStyles from '../../../../../../common/commonCss/Search.module.css'
import BackButton from '../../../../../../common/components/commonUI/BackButton'
import TitleWithSubtitle from '../../../../../../common/components/commonUI/TitleWithSubtitle'
import CustomSkeleton from '../../../../../../common/components/CustomSkeleton'
import DateRangePicker from '../../../../../../common/components/DateRangePicker'
import { formatDate, getFullName } from '../../../../../../common/utils'
import { CustomerApiFactory, CustomerDetail, CustomerPurchase } from '../../../../../../service/api'
import DatePresetBar from '../../../../../dashboard/datePreset/DatePresetBar'
import styles from './ContactActions.module.css'

interface PurchaseDetailTableProps {
    dataSource: CustomerPurchase[]
}
export const PurchaseDetailTable = (props: PurchaseDetailTableProps) => {
    const [t] = useTranslation('translations')
    const columns: ColumnsType<CustomerPurchase> = [
        {
            title: t('orderDateTime'),
            dataIndex: 'date',
            render: (value) => <>{formatDate(dayjs(value), 'DD MMM YYYY HH:mm')}</>,
            sorter: {
                compare: (a, b) => {
                    if (a.date && b.date) {
                        return a.date.valueOf() - b.date.valueOf()
                    } else if (a.date) {
                        return -1
                    } else if (b.date) {
                        return 1
                    }
                    return 0
                }
            },
        },
        {
            title: t('show'),
            dataIndex: 'title',
            sorter: {
                compare: (a, b) => {
                    if (a.title && b.title) {
                        return a.title.localeCompare(b.title)
                    } else if (a.title) {
                        return -1
                    } else if (b.title) {
                        return 1
                    }
                    return 0
                }
            }
        },
        {
            title: t('seat'),
            dataIndex: 'seat',
            sorter: {
                compare: (a, b) => {
                    if (a.seat && b.seat) {
                        return a.seat.localeCompare(b.seat)
                    } else if (a.seat) {
                        return -1
                    } else if (b.seat) {
                        return 1
                    }
                    return 0
                }
            }
        },
        {
            title: t('fare'),
            dataIndex: 'fare',
            sorter: {
                compare: (a, b) => {
                    if (a.fare && b.fare) {
                        return a.fare.localeCompare(b.fare)
                    } else if (a.fare) {
                        return -1
                    } else if (b.fare) {
                        return 1
                    }
                    return 0
                }
            }
        },
        {
            title: t('price'),
            dataIndex: 'price',
            render: (value) => <>&#8364; {value.toFixed(2)}</>,
            sorter: {
                compare: (a, b) => {
                    if (a.price && b.price) {
                        return a.price - b.price
                    } else if (a.price) {
                        return -1
                    } else if (b.price) {
                        return 1
                    }
                    return 0
                }
            }
        }
    ]
    return (
        <>
            <Table
                dataSource={props.dataSource}
                columns={columns}
                className={'tableView'}
                showSorterTooltip={false}
                pagination={{
                    position: ['bottomCenter'],
                    hideOnSinglePage: props.dataSource ? props.dataSource.length <= 10 : undefined,
                    showSizeChanger: true,
                    style: {
                        marginTop: '45px'
                    },
                    responsive: true,
                    showTitle: false
                }}
            />
            {props.dataSource.length === 0 &&
                <Empty
                    image={<NoDataIcon />}
                    imageStyle={{ fontSize: '60px', marginTop: '30px', marginBottom: '-8px' }}
                    style={{ marginTop: '10px', paddingBottom: '10px' }}
                />
            }
        </>
    )
}

export const PurchaseDetail = (): ReactElement => {
    const [t] = useTranslation('translations')
    const api = CustomerApiFactory()
    const location = useLocation()
    const selectedContact: CustomerDetail = location.state as CustomerDetail
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openFilterPopover, setOpenFilterPopover] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<CustomerPurchase[]>([])
    const [filteredData, setFilteredData] = useState<CustomerPurchase[]>([])
    const [currentContact, setCurrentContact] = useState<CustomerDetail>()
    const dateState = useSelector((state: RootState) => state.dashboardState)

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const searchedContent = event.target.value.toLowerCase()
        const filteredData = event.target.value.toLowerCase().trim().length > 0 ?
            dataSource.filter(purchase => {
                if (purchase.title?.toString().toLowerCase().includes(searchedContent) ||
                    purchase.fare?.toString().toLowerCase().includes(searchedContent) ||
                    purchase.seat?.toString().toLowerCase().includes(searchedContent)) {
                    return purchase
                }
            })
            : dataSource
        setFilteredData(filteredData)
    }

    const updateData = () => {
        if (currentContact) {
            api.getCustomerPurcahses(currentContact.pk, undefined, dateState.startDate.valueOf(), dateState.endDate.valueOf()).then(response => {
                const customerPurchases: CustomerPurchase[] = response.data
                setDataSource(customerPurchases)
                setFilteredData(customerPurchases)
            })
        }
    }
    useEffect(() => {
        updateData()
    }, [currentContact, dateState.startDate, dateState.endDate])

    useEffect(() => {
        setCurrentContact(selectedContact)
    }, [selectedContact])
    return (
        <div className={styles.container}>
            {currentContact &&
                <>
                    <div className={styles.titleRowContainer}>
                        <TitleWithSubtitle title={t('purchases')} itemName={getFullName({ firstName: currentContact.firstName, lastName: currentContact.lastName })} />
                        <div className={styles.dateSelectorContainer}>
                            <DateRangePicker disabled={isLoading} />
                        </div>
                    </div>
                    <BackButton />
                    <div className={styles.filtersContainer}>
                        <div className={styles.searchContainer}>
                            <Search
                                className={searchStyles.search}
                                allowClear
                                onChange={handleSearch}
                            />
                        </div>
                        {/*<Popover
                    trigger='click'
                    open={openFilterPopover}
                    placement='right'
                    onOpenChange={open => setOpenFilterPopover(open)}
                >
                    <Button icon={<SlidersIcon />} className={styles.filter} type='text' />
                </Popover>
                */}
                        <div className={styles.datePreset}>
                            <DatePresetBar disabled={isLoading} />
                        </div>
                    </div>
                    <div className={styles.tableContainer}>
                        {isLoading ?
                            <CustomSkeleton height={500} />
                            :
                            <PurchaseDetailTable dataSource={filteredData} />
                        }
                    </div>
                </>
            }
        </div>
    )
}