import { Column, ColumnConfig } from '@ant-design/plots'
import { Button } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import openDetailIcon from '../../../../assets/expand.svg'
import CustomSkeleton from '../../../../common/components/CustomSkeleton'
import NoData from '../../../../common/components/NoData'
import ChartTooltip from '../../../../common/components/charts/ChartTooltip'
import ExportCsv from '../../../../common/components/charts/ExportCsv'
import i18n from '../../../../i18n'
import { DashboardApiFactory } from '../../../../service/api'
import NoSelection from '../../NoSelection'
import styles from '../dashboardGraphs.module.css'

interface HistogramChartProps {
    dataSource: string
    title: string
    tooltip: string
    onDetailClick: (title: string, tooltip: string, data: any[]) => void
    onExportClick: (title: string, data: any[]) => void
}

const HistogramChart = ({ dataSource, title, tooltip, onDetailClick, onExportClick }: HistogramChartProps): ReactElement => {
    const api = DashboardApiFactory()
    const [t] = useTranslation('translations')
    const [soldTickets, setSoldTickets] = useState<number>(0)
    const [config, setConfig] = useState<ColumnConfig>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [histogramData, setHistogramData] = useState<any[]>([])
    const [columnLabels, setColumnLabels] = useState<string[]>([])
    const [columnTypes, setColumnTypes] = useState<string[]>([])
    const dashboardState = useSelector((state: RootState) => state.dashboardState)
    const error = dashboardState.selectedItemsIds.length === 0 ? true : false
    useEffect(() => {
        if (!error) {
            setIsLoading(true)
            const start = dashboardState.startDate.clone()
            const end = dashboardState.endDate.clone()
            api.getChartData(dataSource, {
                pks: dashboardState.selectedItemsIds,
                startDate: start.valueOf(),
                endDate: end.valueOf()
            }).then(response => {
                const data = response.data
                const label1 = i18n.t('translations:' + data.labels[0])
                const label2 = i18n.t('translations:' + data.labels[1])
                setColumnLabels([label1, label2])
                setHistogramData(data.data.map((item) => ({
                    [label1]: dayjs(item[0]).format('DD MMM'),
                    [label2]: item[1],
                })))
                setIsLoading(false)
            })
        }
    }, [dashboardState.selectedItemsIds, dashboardState.startDate, dashboardState.endDate])

    useEffect(() => {
        setSoldTickets(_.sumBy(histogramData, item => item.sales))
        setConfig({
            data: histogramData,
            xField: columnLabels[0],
            yField: columnLabels[1],
            label: {
                position: 'top',
                style: {
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 400,
                    fill: '#989898',
                },
                offsetY: 10
            },
            xAxis: {
                label: {
                    style: {
                        fill: '#191919',
                        fontWeight: 'bolder'
                    },
                },
                tickLine: null
            },
            yAxis: {
                grid: null,
                label: null
            },
            color: '#F7C217',
            maxColumnWidth: 18,
            minColumnWidth: 18,
            renderer: 'svg',
            tooltip: false,
            appendPadding: [15, 0, 5, 0]
        })
    }, [histogramData])


    const handleDetailClick = () => {
        onDetailClick(title, tooltip, histogramData)
    }

    return (
        <div key={title}>
            {isLoading ?
                <CustomSkeleton height={280} />
                :
                <div className={styles.container}>
                    <div className={styles.titleContainer}>
                        <h1 className={styles.title}>
                            {t(title as unknown as TemplateStringsArray)}
                            <span>
                                <ChartTooltip tooltipText={t(tooltip as unknown as TemplateStringsArray)} />
                            </span>
                        </h1>
                        {!error && histogramData.length > 0 && (
                            <div className={styles.more}>
                                <ExportCsv filename={title} data={histogramData} isImage={true} />
                                <Button
                                    className={styles.graphIconButton}
                                    icon={<img src={openDetailIcon} />}
                                    onClick={handleDetailClick} />
                            </div>
                        )}
                    </div>
                    {(!error && histogramData.length > 0 && config) && (
                        <>
                            <span className={styles.totalValue}>{soldTickets}</span>
                            <Column style={{ paddingTop: '30px', height: '73%' }} {...config} />
                        </>
                    )}
                    {!error && histogramData.length === 0 && (
                        <NoData />
                    )}
                    {!!error && (
                        <NoSelection
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                margin: '0 auto',
                                paddingBottom: '30px'
                            }}
                            imageStyle={{ width: '40px', height: '40px', margin: 'auto' }}
                        />
                    )}
                </div>

            }
        </div>
    )
}

export default HistogramChart

