import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { isAdminDashboard, RequireAuth } from '../../App'
import { RootState } from '../../app/store'
import { Setting } from '../../service/model'
import { ActionDetail } from '../crm/contacts/Contacts/ContactDetail/ContactActionsDetail/ActionDetail'
import { PurchaseDetail } from '../crm/contacts/Contacts/ContactDetail/ContactActionsDetail/PurchaseDetail'
import { ContactDetail } from '../crm/contacts/Contacts/ContactDetail/ContactDetail'
import Contacts from '../crm/contacts/Contacts/Contacts'
import { NewContact } from '../crm/contacts/Contacts/NewContact/NewContact'
import Importer from '../crm/contacts/Importer/Importer'
import { ListDetail } from '../crm/contacts/Lists/Detail/ListDetail'
import { List } from '../crm/contacts/Lists/List'
import Dashboard from '../dashboard/newDashboard/Dashboard'
import CardDetail from '../ecommerce/Cards/CardDetail'
import Cards from '../ecommerce/Cards/Cards'
import Inventory from '../ecommerce/Inventory/Inventory'
import { InventoryDetail } from '../ecommerce/Inventory/InventoryDetail'
import { EcommerceOrders } from '../ecommerce/Orders/EcommerceOrders'
import ProductDetail from '../ecommerce/Products/ProductDetail/ProductDetail'
import Products from '../ecommerce/Products/Products'
import TicketCancel from '../tickets/TicketCancel'
import TicketIssue from '../tickets/TicketIssue'
import { UserRole } from '../users/AdminUtils'
import { OrganizationsNew } from '../users/Organizations/AddOrganization/OrganizationsNew'
import Organizations from '../users/Organizations/Organizations'
import { OrganizationsDetail } from '../users/Organizations/OrganizationsDetail'
import AddNewUser from '../users/Users/AddUser/AddNewUser'
import UserDetail from '../users/Users/UserDetail/UserDetail'
import Users from '../users/Users/Users'
import { ROUTE_CARDS, ROUTE_CARDS_DETAIL, ROUTE_CONTACTS, ROUTE_CONTACTS_ADD, ROUTE_CONTACTS_DETAIL, ROUTE_CONTACTS_DETAIL_ACTION, ROUTE_CONTACTS_DETAIL_PURCHASE, ROUTE_CONTACTS_IMPORT, ROUTE_DASHBOARD, ROUTE_INVENTORY, ROUTE_INVENTORY_DETAIL, ROUTE_LISTS, ROUTE_LISTS_DETAIL, ROUTE_NEW_USER, ROUTE_ORDERS, ROUTE_ORGANIZATION_DETAIL, ROUTE_ORGANIZATION_NEW, ROUTE_ORGANIZATIONS, ROUTE_PRODUCTS, ROUTE_PRODUCTS_DETAIL, ROUTE_TICKETS_CANCEL, ROUTE_TICKETS_ISSUE, ROUTE_USER, ROUTE_USER_DETAIL } from './Routes'
import { getHomePage, getSettings } from './RoutingUtils'

export const MainRoutes = (props: { settings: Setting[], role: string }) => {
    const adminSettings = getSettings(props.settings)
    const homePage = getHomePage(adminSettings)
    const limitPermission = props.role === UserRole.OPERATOR || props.role === UserRole.FINANCIAL
    const isAdmin = props.role === UserRole.ADMIN
    const dashboardState = useSelector((state: RootState) => state.dashboardState)
    const userState = useSelector((state: RootState) => state.currentUserState)
    const [adminDashboard, setAdminDashboard] = useState<boolean>()

    useEffect(() => {
        const adminDashboard = userState.user.application.settings.find(setting => setting.key === 'adminDashboard')
        setAdminDashboard(isAdminDashboard(adminDashboard?.value))
    }, [userState.user])
    return (
        <>
            {(userState.user && userState.user.pk !== -1) &&
                <>
                    <Routes>
                        <Route path='*' element={
                            <Navigate to={homePage} replace={true} />
                        } />
                        {adminDashboard &&
                            <>
                                <Route path={ROUTE_DASHBOARD} element={
                                    <RequireAuth>
                                        <Dashboard />
                                    </RequireAuth>
                                } />
                            </>
                        }
                        {!limitPermission &&
                            <>
                                <Route path={ROUTE_TICKETS_ISSUE} element={
                                    <RequireAuth>
                                        <TicketIssue />
                                    </RequireAuth>
                                } />
                                <Route path={ROUTE_TICKETS_CANCEL} element={
                                    <RequireAuth>
                                        <TicketCancel />
                                    </RequireAuth>
                                } />
                                {isAdmin &&
                                    <>
                                        <Route path={ROUTE_PRODUCTS} element={
                                            <RequireAuth>
                                                <Products />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_PRODUCTS_DETAIL} element={
                                            <RequireAuth>
                                                <ProductDetail />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_INVENTORY} element={
                                            <RequireAuth>
                                                <Inventory />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_INVENTORY_DETAIL} element={
                                            <RequireAuth>
                                                <InventoryDetail />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_ORDERS} element={
                                            <RequireAuth>
                                                <EcommerceOrders />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_CARDS} element={
                                            <RequireAuth>
                                                <Cards />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_CARDS_DETAIL} element={
                                            <RequireAuth>
                                                <CardDetail />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_USER} element={
                                            <RequireAuth>
                                                <Users />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_USER_DETAIL} element={
                                            <RequireAuth>
                                                <UserDetail />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_NEW_USER} element={
                                            <RequireAuth>
                                                <AddNewUser />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_ORGANIZATIONS} element={
                                            <RequireAuth>
                                                <Organizations />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_ORGANIZATION_DETAIL} element={
                                            <RequireAuth>
                                                <OrganizationsDetail />
                                            </RequireAuth>
                                        } />
                                        <Route path={ROUTE_ORGANIZATION_NEW} element={
                                            <RequireAuth>
                                                <OrganizationsNew />
                                            </RequireAuth>
                                        } />
                                    </>
                                }
                                <Route path={ROUTE_CONTACTS} element={
                                    <RequireAuth>
                                        <Contacts />
                                    </RequireAuth>
                                } />
                                <Route path={ROUTE_CONTACTS_IMPORT} element={
                                    <RequireAuth>
                                        <Importer />
                                    </RequireAuth>
                                } />
                                <Route path="/" element={
                                    <Navigate to={homePage} replace={true} />
                                } />
                                <Route path={ROUTE_CONTACTS_ADD} element={
                                    <RequireAuth>
                                        <NewContact />
                                    </RequireAuth>
                                } />
                                <Route path={ROUTE_CONTACTS_DETAIL} element={
                                    <RequireAuth>
                                        <ContactDetail />
                                    </RequireAuth>
                                } />
                                <Route path={ROUTE_CONTACTS_DETAIL_PURCHASE} element={
                                    <RequireAuth>
                                        <PurchaseDetail />
                                    </RequireAuth>
                                } />
                                <Route path={ROUTE_CONTACTS_DETAIL_ACTION} element={
                                    <RequireAuth>
                                        <ActionDetail />
                                    </RequireAuth>
                                } />
                            </>
                        }
                        <Route path={ROUTE_CONTACTS} element={
                            <RequireAuth>
                                <Contacts />
                            </RequireAuth>
                        } />
                        <Route path={ROUTE_CONTACTS_IMPORT} element={
                            <RequireAuth>
                                <Importer />
                            </RequireAuth>
                        } />
                        <Route path="/" element={
                            <Navigate to={homePage} replace={true} />
                        } />
                        <Route path={ROUTE_CONTACTS_ADD} element={
                            <RequireAuth>
                                <NewContact />
                            </RequireAuth>
                        } />
                        <Route path={ROUTE_CONTACTS_DETAIL} element={
                            <RequireAuth>
                                <ContactDetail />
                            </RequireAuth>
                        } />
                        <Route path={ROUTE_CONTACTS_DETAIL_PURCHASE} element={
                            <RequireAuth>
                                <PurchaseDetail />
                            </RequireAuth>
                        } />
                        <Route path={ROUTE_CONTACTS_DETAIL_ACTION} element={
                            <RequireAuth>
                                <ActionDetail />
                            </RequireAuth>
                        } />
                        <Route path={ROUTE_LISTS} element={
                            <RequireAuth>
                                <List />
                            </RequireAuth>
                        } />
                        <Route path={ROUTE_LISTS_DETAIL} element={
                            <RequireAuth>
                                <ListDetail />
                            </RequireAuth>
                        } />
                    </Routes>
                </>
            }
        </>
    )
}