import { Button, Modal, Table } from 'antd'
import Search from 'antd/lib/input/Search'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from '../../../../../../app/store'
import { ReactComponent as ArrowIcon } from '../../../../../../assets/arrowDown.svg'
import { ReactComponent as AlertIcon } from '../../../../../../assets/danger.svg'
import { ReactComponent as DeleteIcon } from '../../../../../../assets/delete.svg'
import { ReactComponent as EditIcon } from '../../../../../../assets/edit.svg'
import searchStyles from '../../../../../../common/commonCss/Search.module.css'
import '../../../../../../common/commonCss/tableView.css'
import BackButton from '../../../../../../common/components/commonUI/BackButton'
import TitleWithSubtitle from '../../../../../../common/components/commonUI/TitleWithSubtitle'
import CustomSkeleton from '../../../../../../common/components/CustomSkeleton'
import DateRangePicker from '../../../../../../common/components/DateRangePicker'
import { formatDate, getFullName } from '../../../../../../common/utils'
import { CustomerAction, CustomerApiFactory, CustomerDetail } from '../../../../../../service/api'
import DatePresetBar from '../../../../../dashboard/datePreset/DatePresetBar'
import { ContactActionsLogComponent } from '../ContactActionsLogComponent'
import { getTranslationFromActionType } from '../ContactDetailFooter'
import { ContactAction, ContactActionComponent } from './ContactActionComponent/ContactActionComponent'
import styles from './ContactActions.module.css'

interface HistoryDetailTableProps {
    dataSource: CustomerAction[]
    onDelete: (value: number, description: string) => void
    onEdit: (value: CustomerAction) => void
}

interface ExpandableRow {
    isExpanded: boolean
    id: number | undefined
}

export const HistoryDetailTable = (props: HistoryDetailTableProps) => {
    const [t] = useTranslation('translations')
    const [selectedRow, setSelectedRow] = useState<ExpandableRow>({ isExpanded: false, id: undefined })

    const columns: ColumnsType<CustomerAction> = [
        {
            title: t('dateTime'),
            dataIndex: 'date',
            render: (value) => <>{formatDate(dayjs(value), 'DD MMM YYYY HH:mm')}</>,
            sorter: {
                compare: (a, b) => {
                    if (a.date && b.date) {
                        return a.date.valueOf() - b.date.valueOf()
                    } else if (a.date) {
                        return -1
                    } else if (b.date) {
                        return 1
                    }
                    return 0
                }
            },
        },
        {
            title: t('action'),
            dataIndex: 'type',
            sorter: {
                compare: (a, b) => {
                    if (a.type && b.type) {
                        return a.type - b.type
                    } else if (a.type) {
                        return -1
                    } else if (b.type) {
                        return 1
                    }
                    return 0
                }
            },
            render: (value) => <div>{getTranslationFromActionType(value)}</div>
        },
        {
            title: t('description'),
            dataIndex: 'description',
            render: (_, record) => {
                return <><span dangerouslySetInnerHTML={{ __html: record.description }} className={(selectedRow.isExpanded && selectedRow.id === record.id) ? styles.descriptionExpanded : styles.htmlDescription} /></>
            },
            ellipsis: true
        },
        {
            title: ' ',
            dataIndex: '',
            key: 'x',
            align: 'right',
            width: 50,
            render: (_, record) => <Button
                className={styles.deleteButton}
                shape='circle'
                type='text'
                onClick={() => props.onDelete(record.id, record.description)}
                icon={<DeleteIcon className={styles.trashIcon} />} />,
        },
        {
            title: ' ',
            dataIndex: '',
            key: 'y',
            width: 50,
            render: (_, record) => <Button
                className={styles.editButton}
                shape='circle'
                type='text'
                onClick={() => props.onEdit(record)}
                icon={<EditIcon className={styles.editIcon} />} />,
        },
        {
            title: ' ',
            dataIndex: '',
            key: 'y',
            align: 'left',
            width: 50,
            render: (_, record) => <Button
                shape='circle'
                type='text'
                onClick={() => setSelectedRow({ isExpanded: !selectedRow.isExpanded, id: record.id })}
                icon={<ArrowIcon className={(selectedRow.isExpanded && selectedRow.id === record.id) ? styles.arrow : ''} />} />,
        },
    ]
    return (
        <>
            <Table
                scroll={{ x: 400 }}
                rowKey={record => record.id}
                dataSource={props.dataSource}
                columns={columns}
                className={`tableView ${styles.actionTable}`}
                showSorterTooltip={false}
                pagination={{
                    position: ['bottomCenter'],
                    hideOnSinglePage: props.dataSource ? props.dataSource.length <= 10 : undefined,
                    showSizeChanger: true,
                    style: {
                        marginTop: '45px'
                    },
                    responsive: true,
                    showTitle: false
                }}
            />
        </>
    )
}


export const ActionDetail = (): ReactElement => {
    const [t] = useTranslation('translations')
    const api = CustomerApiFactory()
    const location = useLocation()
    const selectedContact = location.state as CustomerDetail

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openFilterPopover, setOpenFilterPopover] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<CustomerAction[]>([])
    const [filteredData, setFilteredData] = useState<CustomerAction[]>([])

    const [actionModalOpen, setActionModalOpen] = useState<{ open: boolean, action: ContactAction }>({ open: false, action: ContactAction.CALL })
    const [selectedAction, setSelectedAction] = useState<CustomerAction>()

    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState<{ open: boolean, id: number, description: string }>({ open: false, id: -1, description: '' })
    const [isError, setIsError] = useState<boolean>(false)

    const dateState = useSelector((state: RootState) => state.dashboardState)

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const searchedContent = event.target.value.toLowerCase()
        const filteredData = event.target.value.toLowerCase().trim().length > 0 ?
            dataSource.filter(purchase => {
                if (purchase.description?.toString().toLowerCase().includes(searchedContent)) {
                    return purchase
                }
            })
            : dataSource
        setFilteredData(filteredData)
    }

    const handleDelete = () => {
        setIsDeleting(true)
        api.deleteAction(selectedContact.pk, deleteModalOpen.id).then(response => {
            updateData()
        }).catch(() => {
            setIsError(true)
        }).finally(() => {
            setIsDeleting(false)
            setDeleteModalOpen({ open: false, id: -1, description: '' })
        })
    }

    const updateData = () => {
        setIsLoading(true)
        api.getCustomerActions(selectedContact.pk, undefined, dateState.startDate.valueOf(), dateState.endDate.valueOf()).then(response => {
            const customerActions: CustomerAction[] = response.data
            setDataSource(customerActions)
            setFilteredData(customerActions)
        }).finally(() => setIsLoading(false))
    }

    useEffect(() => {
        updateData()
    }, [selectedContact, dateState.startDate, dateState.endDate])

    return (
        <div className={styles.container}>
            <div className={styles.titleRowContainer}>
                <TitleWithSubtitle title={t('history')} itemName={getFullName({ firstName: selectedContact.firstName, lastName: selectedContact.lastName })} />
                <div className={styles.dateSelectorContainer}>
                    <DateRangePicker disabled={isLoading} />
                </div>
            </div>
            <BackButton />
            <div className={styles.filtersContainer}>
                <div className={styles.searchContainer}>
                    <Search
                        className={searchStyles.search}
                        allowClear
                        onChange={handleSearch}
                    />
                </div>
                {/*<Popover
                    trigger='click'
                    open={openFilterPopover}
                    placement='right'
                    onOpenChange={open => setOpenFilterPopover(open)}
                >
                    <Button icon={<SlidersIcon />} className={styles.filter} type='text' />
                </Popover>*/}
                <div className={styles.datePreset}>
                    <DatePresetBar disabled={isLoading} />
                </div>
            </div>
            <div className={styles.actionsContainer}>
                <ContactActionsLogComponent onClick={setActionModalOpen} />
            </div>
            <div className={styles.tableContainer}>
                {isLoading ?
                    <CustomSkeleton height={500} />
                    :
                    <HistoryDetailTable
                        onEdit={value => {
                            setSelectedAction(value)
                            setActionModalOpen({ open: true, action: value.type })
                        }}
                        onDelete={(id, description) => setDeleteModalOpen({ open: true, id: id, description: description })}
                        dataSource={filteredData} />
                }
            </div>
            <ContactActionComponent
                contactPk={selectedContact.pk}
                menu={actionModalOpen}
                selectedAction={selectedAction}
                onFinish={() => updateData()}
                onClose={() => setActionModalOpen(prevState => { return { ...prevState, open: false } })} />
            <Modal
                closable={false}
                className={'modal'}
                open={deleteModalOpen.open}
                footer={
                    <>
                        <Button disabled={isDeleting} className='yellowOutlinedCancelButton' onClick={() => setDeleteModalOpen(prevState => { return { ...prevState, open: false } })}>
                            {t('cancel')}
                        </Button>
                        <Button loading={isDeleting} className={'redFillCancelButton'} onClick={handleDelete}>
                            {t('remove')}
                        </Button>
                    </>
                }>
                <AlertIcon />
                <p>
                    <Trans>{t('deleteActionQuestion')}</Trans>
                    <p className={styles.description}>
                        <span dangerouslySetInnerHTML={{ __html: deleteModalOpen.description }} className={styles.htmlDescription} />
                    </p>
                </p>
            </Modal>
            <Modal
                closable={false}
                className={'modal'}
                open={isError}
                footer={
                    <>
                        <Button className={'blackFillPositiveButton'} onClick={() => {
                            setIsError(false)
                        }}>
                            OK
                        </Button>
                    </>
                }>
                <AlertIcon />
                <p>{t('somethingWentWrong')}</p>
            </Modal>
        </div>
    )
}