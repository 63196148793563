import { Setting } from '../../service/model'
import { ROUTE_CONTACTS, ROUTE_DASHBOARD, ROUTE_PRODUCTS, ROUTE_TICKETS_ISSUE, ROUTE_USER } from './Routes'

export enum UserSettings {
    adminDashboard = 'adminDashboard',
    adminTicketing = 'adminTicketing',
    adminMarketing = 'adminMarketing',
    adminEcommerce = 'adminEcommerce',
    adminSocial = 'adminContacts',
    adminAdministration = 'adminAdministration',
}

export function getSettings(settings: Setting[]): UserSettings[] {
    const currentSettings: UserSettings[] = []
    const adminDashboard = settings.find(setting => setting.key === UserSettings.adminDashboard)
    const adminEcommerce = settings.find(setting => setting.key === UserSettings.adminEcommerce)
    const adminTickets = settings.find(setting => setting.key === UserSettings.adminTicketing)
    //const adminMarketing = settings.find(setting => setting.key === UserSettings.adminMarketing)
    const adminSocial = settings.find(setting => setting.key === UserSettings.adminSocial)
    const adminAdministration = settings.find(setting => setting.key === UserSettings.adminAdministration)
    if (adminDashboard !== undefined && adminDashboard.value === 'true') {
        currentSettings.push(UserSettings.adminDashboard)
    }
    if (adminEcommerce !== undefined && adminEcommerce.value === 'true') {
        currentSettings.push(UserSettings.adminEcommerce)
    }
    if (adminTickets !== undefined && adminTickets.value === 'true') {
        currentSettings.push(UserSettings.adminTicketing)
    }
    /*if (adminMarketing !== undefined && adminMarketing.value === 'true') {
        currentSettings.push(UserSettings.adminMarketing)
    }*/
    if (adminSocial !== undefined && adminSocial.value === 'true') {
        currentSettings.push(UserSettings.adminSocial)
    }
    if (adminAdministration !== undefined && adminAdministration.value === 'true') {
        currentSettings.push(UserSettings.adminAdministration)
    }
    return currentSettings
}

export const getHomePage = (userSettings: UserSettings[]): string => {
    const firstSetting = userSettings[0]
    let homePage = ''
    switch (firstSetting) {
    case UserSettings.adminDashboard:
        homePage = ROUTE_DASHBOARD
        break
    case UserSettings.adminEcommerce:
        homePage = ROUTE_PRODUCTS
        break
    case UserSettings.adminMarketing:
        homePage = 'marketing'
        break
    case UserSettings.adminSocial:
        homePage = ROUTE_CONTACTS
        break
    case UserSettings.adminTicketing:
        homePage = ROUTE_TICKETS_ISSUE
        break
    case UserSettings.adminAdministration:
        homePage = ROUTE_USER
    }
    return homePage
}