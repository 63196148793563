export const ROUTE_DASHBOARD = 'dashboard'
export const ROUTE_DASHBOARD_ORDERS = 'dashboard/orders'

export const ROUTE_CONTACTS = 'contacts'
export const ROUTE_CONTACTS_IMPORT = 'contacts/import'
export const ROUTE_CONTACTS_ADD = 'contacts/add'
export const ROUTE_CONTACTS_DETAIL = 'contacts/detail'
export const ROUTE_CONTACTS_DETAIL_FEEDBACK = 'contacts/detail/feedback'
export const ROUTE_CONTACTS_DETAIL_PURCHASE = 'contacts/detail/purchase'
export const ROUTE_CONTACTS_DETAIL_ACTION = 'contacts/detail/history'

export const ROUTE_LISTS = 'lists'
export const ROUTE_LISTS_DETAIL = 'lists/detail'

export const ROUTE_PRODUCTS = 'products'
export const ROUTE_PRODUCTS_DETAIL = 'products/detail'
export const ROUTE_INVENTORY = 'inventory'
export const ROUTE_INVENTORY_DETAIL = 'inventory/detail'
export const ROUTE_ORDERS = 'orders'
export const ROUTE_CARDS = 'cards'
export const ROUTE_CARDS_DETAIL = 'cards/detail'

export const ROUTE_TICKETS_ISSUE = 'ticket-issue'
export const ROUTE_TICKETS_CANCEL = 'ticket-cancel'

export const ROUTE_USER = 'users'
export const ROUTE_ORGANIZATIONS = 'organizations'
export const ROUTE_ORGANIZATION_DETAIL = 'organizations/detail'
export const ROUTE_ORGANIZATION_NEW = 'organizations/new'
export const ROUTE_USER_DETAIL = 'users/user-detail'
export const ROUTE_NEW_USER = 'users/new'