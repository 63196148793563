import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SortOrder } from 'antd/lib/table/interface'
import { CardFilterKey } from '../../pages/ecommerce/Cards/Cards'

export interface TableSorter {
    key: string
    order: SortOrder
}
interface CardState {
    page: number
    tab: CardFilterKey
    searchedText: string
    cardVariant?: number
    tableSorter?: TableSorter
}

const initialState: CardState = {
    page: 1,
    tab: 'all',
    searchedText: '',
    cardVariant: undefined,
    tableSorter: undefined
}

export const cardSlice = createSlice({
    name: 'community',
    initialState,
    reducers: {
        setPageState: (state, action: PayloadAction<CardState>) => {
            state.page = action.payload?.page
            state.searchedText = action.payload.searchedText
            state.tab = action.payload.tab
            state.cardVariant = action.payload.cardVariant
        },
        setText: (state, action: PayloadAction<string>) => {
            state.searchedText = action.payload
        },
        setTab: (state, action: PayloadAction<CardFilterKey>) => {
            state.tab = action.payload
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload
        },
        setCardVariant: (state, action: PayloadAction<number>) => {
            state.cardVariant = action.payload
        },
        setSorter: (state, action: PayloadAction<TableSorter>) => {
            state.tableSorter = action.payload
        },
        resetPageState: (state, action: PayloadAction<CardState>) => {
            state = initialState
        }
    }
})

export const { setPageState, setPage, setText, setCardVariant, setTab, setSorter } = cardSlice.actions

export default cardSlice.reducer