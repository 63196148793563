import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './ContactEditor.css'

interface editorProps {
    toolbarClassName: string
    description: string
    onChange: (value: string) => void
}

const EMPTY_HTML_STRING = '<p><br></p>'
export const ContactEditor = (props: editorProps) => {
    const [state, setState] = useState<string>(props.description)
    const handleChange = (value: string) => {
        if (value === EMPTY_HTML_STRING) {
            props.onChange('')
            setState('')
        } else {
            props.onChange(value)
            setState(value)
        }
    }

    useEffect(() => {
        setState(props.description)
    }, [props.description])

    return (
        <div className='text-editor'>
            <ReactQuill
                value={state}
                defaultValue={props.description}
                onChange={value => handleChange(value)}
                className='contactEditor' />
        </div>
    )
}

