import Icon from '@ant-design/icons'
import { Button, Empty, Popover, Table, TablePaginationConfig, Tabs, notification } from 'antd'
import { FilterValue } from 'antd/es/table/interface'
import Search from 'antd/lib/input/Search'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TableSorter, setCardVariant, setPage, setPageState, setSorter, setTab, setText } from '../../../app/Reducers/CardSlice'
import { RootState } from '../../../app/store'
import { ReactComponent as NoDataIcon } from '../../../assets/cloud-cross.svg'
import { ReactComponent as TrashIcon } from '../../../assets/delete.svg'
import { ReactComponent as SlidersIcon } from '../../../assets/filters.svg'
import { Colors } from '../../../common/Colors'
import tabStyles from '../../../common/commonCss/Tabs.module.css'
import '../../../common/commonCss/buttons.css'
import '../../../common/commonCss/tableView.css'
import CustomSkeleton from '../../../common/components/CustomSkeleton'
import DateRangePicker from '../../../common/components/DateRangePicker'
import TitleWithSubtitle from '../../../common/components/commonUI/TitleWithSubtitle'
import { formatDate } from '../../../common/utils'
import { Card, CardsApiFactory } from '../../../service/api'
import DatePresetBar from '../../dashboard/datePreset/DatePresetBar'
import { OperationStatus } from '../../users/AdminUtils'
import { DeleteModalManager, ModalOptions } from '../DeleteModalManager'
import { CardStatus, getLabel } from '../ECommerceUtils'
import { VariantsPopover } from '../Products/VariantsPopover'
import styles from './Cards.module.css'
import ExportMenuPopoverButton from './ExportMenuPopoverButton'
export const PLURAL = 2
export const SINGULAR = 1

export type CardFilterKey = CardStatus | 'all'
type CardFilterFn = (value: Card, index: number, obj: Card[]) => unknown
const categoryFilters: Record<CardFilterKey, CardFilterFn> = {
    all: (() => true),
    [CardStatus.ASSIGNED]: (x => x.status === CardStatus.ASSIGNED),
    [CardStatus.ACTIVE]: (x => x.status === CardStatus.ACTIVE),
    [CardStatus.REPLACED]: (x => x.status === CardStatus.REPLACED),
    [CardStatus.EXPIRED]: (x => x.status === CardStatus.EXPIRED)
}

function compactMap<T, U>(array: T[], transform: (element: T, index: number, array: T[]) => U | null | undefined): U[] {
    return array.map(transform).filter((value): value is U => value !== null && value !== undefined)
}

const Cards = (): ReactElement => {
    const api = CardsApiFactory()
    const [t, i18n] = useTranslation('translations')
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [currentSorter, setCurrentSorter] = useState<TableSorter>()
    const dateState = useSelector((state: RootState) => state.dashboardState)
    const pageState = useSelector((state: RootState) => state.ecommerceState)
    const dispatch = useDispatch()

    const [isMenuOpen, setMenuOpen] = useState(false)

    const [searchedText, setSearchedText] = useState<string>('')
    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const searchedText = event.target.value
        setSearchedText(searchedText)
        dispatch(setText(searchedText))
        applyFilters()
    }

    const [openFilterPopover, setOpenFilterPopover] = useState(false)
    const [variant, setVariant] = useState<number>()
    const [loaded, setLoaded] = useState<boolean>(false)
    const [modalType, setModalType] = useState<ModalOptions>(ModalOptions.NONE)

    const [deletionResult, setDeletionResult] = useState<OperationStatus>({ operationErrors: [], operationSuccessful: [] })
    const [isCardDeleting, setIsCardDeleting] = useState<boolean>(false)
    const [cardDeletionPk, setCardDeletionPk] = useState<number>()

    const [activeKey, setActiveKey] = useState<CardFilterKey>('all')
    const [dataSource, setDataSource] = useState<Card[]>([])
    const applyFilters = (): Card[] => {
        return dataSource.filter(categoryFilters[activeKey]).filter(card => {
            return searchedText.length === 0
                || card.code.toString().includes(searchedText)
                || card.customer?.name.toLocaleLowerCase().includes(searchedText)
                || card.customer?.email.toLocaleLowerCase().includes(searchedText)
        })
    }
    const filteredDataSource = applyFilters()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    useEffect(() => {
        updateData()
    }, [dateState, variant, activeKey])

    const onRowClick = (selectedCard: Card) => {
        dispatch(setPageState({ page: currentPage, searchedText: searchedText, tab: activeKey, cardVariant: variant, tableSorter: undefined }))
        const params = {
            selectedCard: selectedCard
        }
        navigate('detail', { state: params })
    }

    const createExportData = (data: Card[]) => {
        return data.map(({
            code,
            type,
            sellingDate,
            sellingPoint,
            activationDate,
            status,
            customer,
            minors
        }) => ({
            [t('cardId')]: code,
            [t('type')]: type,
            [t('purchaseDateTime')]: formatDate(dayjs(sellingDate), 'DD MMM YYYY HH:mm'),
            [t('pointOfSale')]: sellingPoint ? sellingPoint.name : '',
            [t('activationDateTime')]: activationDate ? formatDate(dayjs(activationDate), 'DD MMM YYYY HH:mm') : '',
            [t('status')]: i18n.t(`translations:${status}`),
            [t('customer')]: customer?.name,
            [t('email')]: customer?.email,
            [t('minorEntrances')]: minors
        }))
    }
    const selectedRows = compactMap(filteredDataSource, (element, elementIndex) => {
        return selectedRowKeys.includes(element.pk) ? elementIndex : null
    })

    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) => {
        dispatch(setSorter({ key: sorter.columnKey.toString(), order: sorter.order }))
    }
    const handleDelete = () => {
        setIsCardDeleting(true)
        if (cardDeletionPk !== undefined) {
            api.deleteCard(cardDeletionPk).then(() => {
                setModalType(ModalOptions.SUCCESSFUL)
            }).catch(() => {
                setModalType(ModalOptions.ERROR)
                notification.error({
                    message: t('error'),
                    description: `${t('somethingWentWrong')}: ${cardDeletionPk}`,
                })
            }).finally(() => {
                setIsCardDeleting(false)
                updateData()
            })
        }
        else {
            selectedRows.map((index) => {
                const card = filteredDataSource[index]
                api.deleteCard(card.pk).then(() =>
                    setDeletionResult(prevState => (
                        {
                            operationErrors: prevState.operationErrors, operationSuccessful: [...prevState.operationSuccessful, card.pk]
                        })
                    )
                ).catch((error: AxiosError) => {
                    notification.error({
                        message: t('error'),
                        description: t('somethingWentWrong')
                    })
                    deletionResult.operationErrors.push({ pk: card.pk, error: error.name })
                }).finally(() => {
                    if (deletionResult.operationErrors.length > 0) {
                        setModalType(ModalOptions.ERROR)
                    } else {
                        setModalType(ModalOptions.SUCCESSFUL)
                    }
                    updateData()
                    setIsCardDeleting(false)
                })
            })
        }
    }
    const updateData = () => {
        setLoaded(false)
        api.getCards({
            startDate: dateState.startDate.valueOf(),
            endDate: dateState.endDate.valueOf(),
            pks: variant ? [variant] : []
        }).then(response => {
            setDataSource(response.data)
            setLoaded(true)
            setCurrentPage(pageState.page)
            setSearchedText(pageState.searchedText)
            setActiveKey(pageState.tab as CardFilterKey)
            setVariant(pageState.cardVariant)
            setCurrentSorter(pageState.tableSorter)
        }).finally(() => setLoaded(true))
        setDeletionResult({ operationErrors: [], operationSuccessful: [] })
    }

    const handleModalChange = (value: ModalOptions) => {
        setModalType(value)
        if (value !== ModalOptions.SUCCESSFUL) {
            setCardDeletionPk(undefined)
        }
    }

    const getCardIdFromPk = (cardPk: number): string => {
        const card = dataSource.find(card => card.pk == cardPk)
        return card && card.code ? card.code : '_'
    }
    return (
        <div className={styles.container}>
            <div className={styles.titleRowContainer}>
                <TitleWithSubtitle title={t('cards')} />
                <div className={styles.dateSelectorContainer}>
                    <DateRangePicker disabled={!loaded} />
                    <ExportMenuPopoverButton
                        dataSource={createExportData(filteredDataSource)}
                        filename={t('cards')}
                        selected={selectedRows.length > 0 ? selectedRows : undefined}
                        open={isMenuOpen}
                        onOpenChange={value => setMenuOpen(value)}
                        deleteSelected={() => setModalType(ModalOptions.DELETE)}
                    />
                </div>
            </div>
            <div className={styles.filtersContainer}  >
                <div className={styles.searchContainer}>
                    <Search
                        disabled={!loaded}
                        value={searchedText}
                        className={styles.search}
                        allowClear
                        onChange={handleSearch}
                    />
                </div>
                <Popover
                    content={
                        <VariantsPopover
                            defaultValue={variant}
                            onValueChange={value => {
                                setOpenFilterPopover(false)
                                setVariant(value?.pk)
                                dispatch(setCardVariant(value!.pk))
                            }}
                            onCancel={() => setOpenFilterPopover(false)}
                        />
                    }
                    trigger='click'
                    open={openFilterPopover}
                    placement='right'
                    onOpenChange={open => setOpenFilterPopover(open)}
                >
                    <Button
                        disabled={!loaded}
                        className={styles.filter}
                        type='text'
                        icon={<SlidersIcon className={`${variant !== undefined && styles.filterIconActive}`} />} />
                </Popover>
                <div className={styles.datePreset}>
                    <DatePresetBar disabled={!loaded} />
                </div>
            </div>
            <DeleteModalManager
                modalText={cardDeletionPk !== undefined ?
                    t('cardSingleDeletionQuestion', { cardId: getCardIdFromPk(cardDeletionPk) })
                    :
                    t('deleteCardQuestion', { count: selectedRowKeys.length })}
                modalResultText={cardDeletionPk !== undefined ?
                    t('cardSingleDeletionResult', { cardId: getCardIdFromPk(cardDeletionPk) })
                    :
                    t('deleteCardResult', { count: selectedRowKeys.length })
                }
                onChange={handleModalChange}
                modalType={modalType}
                onDelete={handleDelete}
                isDeleting={isCardDeleting} />
            <div style={{ marginTop: 40 }}>
                {loaded ?
                    <>
                        <Tabs
                            activeKey={activeKey}
                            className={tabStyles.tabs}
                            items={
                                Object.keys(categoryFilters).map((key) => {
                                    return {
                                        key: key,
                                        label: i18n.t(getLabel(key))
                                    }
                                })
                            }
                            onChange={key => {
                                dispatch(setTab(key as CardFilterKey))
                                setActiveKey(key as CardFilterKey)
                            }}
                        />
                        <Table<Card> className={'tableView'}
                            onChange={handleTableChange}
                            dataSource={filteredDataSource}
                            pagination={{
                                current: currentPage,
                                position: ['bottomCenter'],
                                hideOnSinglePage: filteredDataSource.length <= 10,
                                showSizeChanger: true,
                                style: {
                                    marginTop: '45px'
                                },
                                responsive: true,
                                showTitle: false,
                                onChange: (page) => {
                                    dispatch(setPage(page))
                                    setCurrentPage(page)
                                }
                            }}
                            rowKey="pk"
                            rowSelection={rowSelection}
                            onRow={(record) => {
                                return {
                                    onClick: () => {
                                        onRowClick(record)
                                    }
                                }
                            }}
                        >
                            <Table.Column<Card>
                                defaultSortOrder={currentSorter?.key === 'code' ? currentSorter.order : null}
                                title={t('cardId')}
                                key='code'
                                dataIndex="code"
                                sorter={(a, b) => a.code.localeCompare(b.code)} />
                            <Table.Column<Card>
                                defaultSortOrder={currentSorter?.key === 'type' ? currentSorter.order : null}
                                title={t('type')}
                                key='type'
                                dataIndex="type"
                                sorter={(a, b) => a.type.localeCompare(b.type)} />
                            <Table.Column<Card>
                                defaultSortOrder={currentSorter?.key === 'sellingDate' ? currentSorter.order : null}
                                title={t('purchaseDateTime')}
                                key='sellingDate'
                                dataIndex="sellingDate"
                                sorter={(a, b) => a.sellingDate.valueOf() - b.sellingDate.valueOf()}
                                render={
                                    (datetime: Date) => (
                                        formatDate(dayjs(datetime), 'DD MMM YYYY HH:mm')
                                    )
                                } />
                            <Table.Column<Card>
                                defaultSortOrder={currentSorter?.key === 'sellingPoint' ? currentSorter.order : null}
                                title={t('pointOfSale')}
                                key='name'
                                dataIndex={['sellingPoint', 'name']}
                                sorter={(a, b) => {
                                    if (a.sellingPoint && b.sellingPoint) {
                                        return a.sellingPoint.name.localeCompare(b.sellingPoint.name)
                                    } else if (a.sellingPoint) {
                                        return 1
                                    } else if (b.sellingPoint) {
                                        return -1
                                    } else {
                                        return 0
                                    }
                                }} />
                            <Table.Column<Card>
                                defaultSortOrder={currentSorter?.key === 'activationDate' ? currentSorter.order : null}
                                title={t('activationDateTime')}
                                key='activationDate'
                                dataIndex="activationDate"
                                sorter={(a, b) => {
                                    if (a.activationDate && b.activationDate) {
                                        return a.activationDate.valueOf() - b.activationDate.valueOf()
                                    } else if (a.activationDate) {
                                        return 1
                                    } else if (b.activationDate) {
                                        return -1
                                    } else {
                                        return 0
                                    }
                                }
                                }
                                render={
                                    (activationDate: Date) => (
                                        activationDate ? formatDate(dayjs(activationDate), 'DD MMM YYYY HH:mm') : ''
                                    )
                                } />
                            <Table.Column<Card>
                                defaultSortOrder={currentSorter?.key === 'status' ? currentSorter.order : null}
                                title={t('status')}
                                key='status'
                                dataIndex="status"
                                sorter={(a, b) => a.status.localeCompare(b.status)}
                                render={(status: CardStatus) => (<>{i18n.t(`translations:${status}`)}</>)} />
                            <Table.Column<Card>
                                defaultSortOrder={currentSorter?.key === 'name' ? currentSorter.order : null}
                                title={t('customer')}
                                key='name'
                                dataIndex={['customer', 'name']}
                                sorter={(a, b) => {
                                    if (a.customer && b.customer) {
                                        return a.customer.name.localeCompare(b.customer.name)
                                    } else if (a.customer) {
                                        return 1
                                    } else if (b.customer) {
                                        return -1
                                    } else {
                                        return 0
                                    }
                                }} />
                            <Table.Column<Card>
                                defaultSortOrder={currentSorter?.key === 'email' ? currentSorter.order : null}
                                title={t('email')}
                                key='email'
                                dataIndex={['customer', 'email']}
                                sorter={(a, b) => {
                                    if (a.customer && b.customer) {
                                        return a.customer.email.localeCompare(b.customer.email)
                                    } else if (a.customer) {
                                        return 1
                                    } else if (b.customer) {
                                        return -1
                                    } else {
                                        return 0
                                    }
                                }} />
                            <Table.Column<Card>
                                defaultSortOrder={currentSorter?.key === 'minors' ? currentSorter.order : null}
                                title={t('minorEntrances')}
                                key={'minors'}
                                dataIndex="minors"
                                sorter={(a, b) => { return a.minors - b.minors }} />
                            <Table.Column<Card>
                                title={' '}
                                key='x'
                                dataIndex={''}
                                render={(value, record, index) => {
                                    return <Button
                                        className={styles.deleteButton}
                                        shape='circle'
                                        type='text'
                                        onClick={(value) => {
                                            value.preventDefault()
                                            value.stopPropagation()
                                            setCardDeletionPk(record.pk)
                                            setModalType(ModalOptions.DELETE)
                                        }}
                                        icon={<TrashIcon className={styles.trashIcon} />} />
                                }}
                            />
                        </Table>
                        {dataSource.length === 0 &&
                            <Empty
                                image={<Icon component={NoDataIcon} />}
                                imageStyle={{ fontSize: '60px', marginTop: '50px', marginBottom: '-8px' }}
                                style={{ marginTop: '50px', paddingBottom: '180px' }}
                                description={(
                                    <span style={{ color: Colors.black }}>{i18n.t('noData')}</span>
                                )} />
                        }
                    </>
                    : <CustomSkeleton height={400}></CustomSkeleton>}
            </div>
        </div>
    )
}

export default Cards
