import { Collapse, FormInstance } from 'antd'
import { CollapseProps } from 'antd/lib'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as YellowArrow } from '../../../../../assets/arrowDown.svg'
import { useMobileMediaQuery } from '../../../../../hooks/useMobileMediaQuery'
import { CustomerApiFactory, CustomerDetail, CustomerDetailCustomerListsInner, CustomerList } from '../../../../../service/api'
import { ContactForm } from './ContactForm'
import styles from './ContactFormComponent.module.css'
import { ListComponent } from './ListComponent'

interface ContactFormProps {
    disabled?: boolean
    selectedContact?: CustomerDetail
    form: FormInstance<any>
    handleFinish: (values: any, enabled: boolean) => void
    onListChange: (selectedLists: CustomerDetailCustomerListsInner[]) => void
}

export const ContactFormComponent = (props: ContactFormProps): ReactElement => {
    const isMobile = useMobileMediaQuery()
    const customerApi = CustomerApiFactory()
    const [t] = useTranslation('translations')
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [lists, setLists] = useState<CustomerList[]>([])

    useEffect(() => {
        customerApi.getLists().then(response => {
            setLists(response.data)
        })
    }, [])

    useEffect(() => {
        setIsDisabled(props.disabled ?? false)
    }, [props.disabled])

    const contactForm =
        <>
            <ContactForm
                disabled={isDisabled}
                selectedContact={props.selectedContact}
                form={props.form}
                onFinish={(values, enabled) => props.handleFinish(values, enabled)} />
        </>

    const listComponent =
        <>
            <ListComponent
                disabled={props.disabled ?? false}
                onChange={(selectedLists: CustomerDetailCustomerListsInner[]) => {
                    props.onListChange(selectedLists)
                }}
                selectedContact={props.selectedContact}
                lists={lists} />
        </>

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: t('userInfo'),
            children: contactForm
        },
        {
            key: '2',
            label: t('listsAndPermissions'),
            children: listComponent
        },
    ]

    return (
        <>
            {isMobile ?
                <Collapse
                    className={styles.collpasibleList}
                    expandIconPosition='end'
                    collapsible='header'
                    items={items}
                    expandIcon={() => <YellowArrow />}
                />
                :
                <div className={styles.layout}>
                    <div className={styles.layoutMain}>
                        {contactForm}
                    </div>
                    <div className={styles.listComponentContainer}>
                        <div className={styles.listComponent}>
                            {listComponent}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}