import { Button, Checkbox, Modal, notification, Table, Tabs } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { TableRowSelection } from 'antd/lib/table/interface'
import { Key, ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from '../../../../assets/arrowDown.svg'
import { ReactComponent as CloneIcon } from '../../../../assets/clone.svg'
import { ReactComponent as CustomListIcon } from '../../../../assets/customList.svg'
import { ReactComponent as AlertIcon } from '../../../../assets/danger.svg'
import { ReactComponent as InterestsListIcon } from '../../../../assets/interestsList.svg'
import { ReactComponent as ListIcon } from '../../../../assets/list.svg'
import tabStyles from '../../../../common/commonCss/Tabs.module.css'
import TitleWithSubtitle from '../../../../common/components/commonUI/TitleWithSubtitle'
import CustomSkeleton from '../../../../common/components/CustomSkeleton'
import PopupMenu from '../../../../common/components/PopupMenu'
import i18n from '../../../../i18n'
import { CustomerApiFactory, CustomerList } from '../../../../service/api'
import { OperationStatus } from '../../../users/AdminUtils'
import { CreateListModal } from './Detail/CreateListModal'
import styles from './Lists.module.css'


export const getCategoryName = (category: ListCategory) => {
    switch (category) {
    case ListCategory.ALL:
        return i18n.t('all')
    case ListCategory.CUSTOM:
        return i18n.t('custom')
    case ListCategory.INTERESTS:
        return i18n.t('interests')
    }
}

interface ListTableProps {
    selectedKeys: React.Key[]
    dataSource: CustomerList[]
    onSelect: (selectedItems: Key[]) => void
    onRowClick: (clickedRow: CustomerList) => void
    onClone: (record: CustomerList) => void
}

export const ListTable = (props: ListTableProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(props.selectedKeys)
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
        props.onSelect(newSelectedRowKeys)
    }

    const rowSelection: TableRowSelection<CustomerList> = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const columns: ColumnsType<CustomerList> = [
        {
            title: t('lists'),
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => {
                    if (a.name && b.name) {
                        return a.name.localeCompare(b.name)
                    }
                    if (a.name) {
                        return -1
                    }
                    if (b.name) {
                        return 1
                    }
                    return 0
                }
            },
            render: (name: string, record: CustomerList) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={styles.categoryIcon}>
                        {record.category === ListCategory.INTERESTS ?
                            <InterestsListIcon /> :
                            <CustomListIcon />}
                    </div>
                    {name}
                </span>
            )
        },
        {
            title: t('shortDescription'),
            dataIndex: 'shortDescription',
            sorter: {
                compare: (a, b) => {
                    if (a.shortDescription && b.shortDescription) {
                        return a.shortDescription.localeCompare(b.shortDescription)
                    }
                    if (a.shortDescription) {
                        return -1
                    }
                    if (b.shortDescription) {
                        return 1
                    }
                    return 0
                }
            },
        },
        {
            title: t('category'),
            dataIndex: 'category',
            sorter: {
                compare: (a, b) => a.category - b.category
            },
            render: (category: number, record: CustomerList) => (
                getCategoryName(category as ListCategory)
            )
        },
        {
            title: t('contacts'),
            dataIndex: 'customerCount',
            sorter: {
                compare: (a, b) => a.customerCount - b.customerCount
            },
            render: (customerCount: number, record: CustomerList) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {customerCount} {(customerCount === 0 || customerCount > 1) ? t('contacts') : t('contact')}
                </span>
            )
        },
        {
            title: ' ',
            dataIndex: '',
            key: 'x',
            align: 'right',
            width: 50,
            render: (_, record) => <Button
                className={styles.cloneButton}
                shape='circle'
                type='text'
                onClick={(event) => {
                    event.stopPropagation()
                    props.onClone({ ...record })
                }}
                icon={<CloneIcon className={styles.cloneIcon} />} />,
        },
        /*{
            title: ' ',
            dataIndex: '',
            key: 'y',
            width: 50,
            render: (_, record) => <Button
                className={styles.sendButton}
                shape='circle'
                type='text'
                onClick={(event) => {
                    event.stopPropagation()
                    notification.info({
                        message: 'send to a list'
                    })
                }}
                icon={<SendIcon className={styles.sendIcon} />} />,
        }*/
    ]

    return (
        <>
            <Table
                scroll={{ x: 400 }}
                rowSelection={rowSelection}
                rowKey={record => record.pk}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            props.onRowClick(record)
                        }
                    }
                }}
                dataSource={props.dataSource}
                columns={columns}
                className={`tableView ${styles.listTable}`}
                showSorterTooltip={false}
                pagination={{
                    locale: {
                        items_per_page: i18n.t('itemsPerPage'),
                    },
                    position: ['bottomCenter'],
                    hideOnSinglePage: props.dataSource.length <= 10,
                    showSizeChanger: true,
                    style: {
                        marginTop: '45px'
                    },
                    responsive: true,
                    showTitle: false
                }}
            />
        </>
    )
}

export enum ListCategory {
    ALL = -1,
    CUSTOM = 1,
    INTERESTS = 2
}

export const List = (): ReactElement => {
    const [t] = useTranslation('translations')
    const api = CustomerApiFactory()
    const navigate = useNavigate()

    const [lists, setLists] = useState<CustomerList[]>([])
    const [filteredLists, setFilteredLists] = useState<CustomerList[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [activeKey, setActiveKey] = useState<number>(ListCategory.ALL)
    const [selectedKeys, setSelectedKeys] = useState<Key[]>([])

    const [popupVisible, setPopupVisible] = useState<boolean>(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [deletionResult, setDeletionResult] = useState<OperationStatus>({ operationErrors: [], operationSuccessful: [] })

    const [isOperating, setIsOperating] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)

    const [createModalOpen, setCreateModalOpen] = useState<{ open: boolean, list: CustomerList | undefined }>({ open: false, list: undefined })

    const [withCustomers, setWithCustomers] = useState<boolean>(false)

    const menuContent = [
        {
            key: 1,
            label: <div>{t('delete')}</div>,
            disabled: selectedKeys.length === 0,
            onClick: () => {
                setPopupVisible(false)
                setIsDeleteModalOpen(true)
            }
        }
    ]
    const updateLists = () => {
        setLoading(true)
        api.getLists().then(response => {
            const lists = response.data
            setLists(lists)
            setFilteredLists(lists)
        }).catch(() => {
            notification.error({
                message: t('somethingWentWrong')
            })
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        updateLists()
    }, [])

    const handleRowClick = (list: CustomerList) => {
        navigate('detail', { state: list })
    }
    const handleDelete = () => {
        setIsOperating(true)
        selectedKeys.forEach(key => {
            const listPk = Number(key.valueOf())
            console.log(withCustomers)
            api.deleteList(listPk, withCustomers).then(response => {
                setDeletionResult(prevState => (
                    {
                        operationErrors: prevState.operationErrors,
                        operationSuccessful: [...prevState.operationSuccessful, listPk]
                    }))
            }).catch(error => {
                deletionResult.operationErrors.push({ pk: listPk, error: error.name })
            }).finally(() => {
                setIsOperating(false)
            })
        })
    }
    useEffect(() => {
        if (selectedKeys.length > 0 && deletionResult.operationSuccessful.length === selectedKeys.length) {
            setIsOperating(false)
            setIsDeleteModalOpen(false)
            updateLists()
            reset()
        } else if (deletionResult.operationErrors.length > 0) {
            setIsError(true)
        }
    }, [deletionResult])

    const reset = () => {
        setDeletionResult({ operationErrors: [], operationSuccessful: [] })
    }
    return (
        <>
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <TitleWithSubtitle title={t('lists')} items={lists.length} itemName={t('segments')} />
                    {/*<Tag className={styles.tag}>{t('list')}</Tag>*/}
                    <Button
                        onClick={() => setCreateModalOpen({ open: true, list: undefined })}
                        icon={<ListIcon />}
                        className={`yellowFillPositiveButton ${styles.createListButton}`}>
                        {t('createList')}
                    </Button>
                </div>
                <div className={styles.tableContainer}>
                    {loading ?
                        <CustomSkeleton height={400} />
                        :
                        <>
                            <div className={styles.tabsRow}>
                                <Tabs
                                    className={tabStyles.tabs}
                                    items={[
                                        { key: ListCategory.ALL.toString(), label: getCategoryName(ListCategory.ALL) },
                                        { key: ListCategory.CUSTOM.toString(), label: getCategoryName(ListCategory.CUSTOM) },
                                        { key: ListCategory.INTERESTS.toString(), label: getCategoryName(ListCategory.INTERESTS) }
                                    ]}
                                    onChange={value => {
                                        const key = parseInt(value)
                                        setFilteredLists(key > 0 ? lists.filter(list => {
                                            return list.category === key
                                        }) : lists)
                                    }}
                                />
                                <PopupMenu popupVisible={popupVisible} content={menuContent} >
                                    <Button
                                        className={styles.bulkButton}
                                        icon={<ArrowIcon />}
                                        iconPosition='end'>{t('bulkActions')}</Button>
                                </PopupMenu>
                            </div>
                            <div>
                                <ListTable
                                    dataSource={filteredLists}
                                    selectedKeys={selectedKeys}
                                    onSelect={(rowSelected) => setSelectedKeys(rowSelected)}
                                    onRowClick={(list) => handleRowClick(list)}
                                    onClone={(list) => setCreateModalOpen({ open: true, list: list })} />
                            </div>
                            <CreateListModal
                                onSave={() => {
                                    setCreateModalOpen({ open: false, list: undefined })
                                    updateLists()
                                }}
                                onCancel={() => setCreateModalOpen({ open: false, list: undefined })}
                                openSettings={createModalOpen} />
                            <Modal
                                closable={false}
                                className={'modal'}
                                open={isDeleteModalOpen}
                                footer={
                                    <>
                                        <div>
                                            <Button disabled={isOperating} className={`yellowOutlinedCancelButton ${styles.cancelButton}`} onClick={() => setIsDeleteModalOpen(false)}>
                                                {t('cancel')}
                                            </Button>
                                            <Button
                                                loading={isOperating}
                                                className={'redFillCancelButton'}
                                                onClick={() => handleDelete()}>
                                                {t('delete')}
                                            </Button>
                                        </div>
                                    </>
                                }>
                                <AlertIcon />
                                <p>
                                    <Trans>{t('deleteListQuestion', { count: selectedKeys.length })}</Trans>
                                </p>
                                <Checkbox checked={withCustomers} className={styles.checkbox} onChange={(event) => setWithCustomers(event.target.checked)}>{t('removeCutomersFromCrm')}</Checkbox>
                            </Modal>
                            <Modal
                                closable={false}
                                className={'modal'}
                                open={isError}
                                footer={
                                    <>
                                        <Button className={'blackFillPositiveButton'} onClick={() => {
                                            reset()
                                            setIsError(false)
                                        }}>
                                            OK
                                        </Button>
                                    </>
                                }>
                                <AlertIcon></AlertIcon>
                                <p>{t('somethingWentWrong')}</p>
                            </Modal>
                        </>
                    }
                </div>
            </div>
        </>
    )
}