import { Button, Form, Input, Modal, notification } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as OkIcon } from '../../../../../assets/alert.svg'
import { CustomerApiFactory, CustomerList } from '../../../../../service/api'
import { ListCategory } from '../List'
import styles from './CreateListModal.module.css'

interface CreateListModalProps {
    openSettings: { open: boolean, list: CustomerList | undefined }
    onCancel: () => void
    onSave: (list: CustomerList) => void
}
export const CreateListModal = (props: CreateListModalProps): ReactElement => {
    const api = CustomerApiFactory()
    const [t] = useTranslation('translations')
    const [open, setOpen] = useState<boolean>(props.openSettings.open)
    const [list, setList] = useState<CustomerList>()
    const [response, setResponse] = useState<CustomerList>()
    const [operationSuccessful, setOperationSuccessful] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [form] = Form.useForm()
    const handleFinish = (values: CustomerList) => {
        setIsSaving(true)
        list ?
            api.cloneList(list.pk, values.name).then(response => {
                setResponse(response.data)
                setOperationSuccessful(true)
            }).catch(() => {
                notification.error({ message: t('somethingWentWrong') })
            }).finally(() => {
                props.onCancel()
                setIsSaving(false)
            })
            :
            api.saveList({ name: values.name, category: ListCategory.CUSTOM }).then(response => {
                setResponse(response.data)
                setOperationSuccessful(true)
            }).catch((error) => {
                notification.error({ message: t('somethingWentWrong') })
            }).finally(() => {
                setIsSaving(false)
            })
    }

    useEffect(() => {
        setOpen(props.openSettings.open)
        if (!props.openSettings.open) {
            form.resetFields()
            form.setFieldsValue({ name: '' })
        } else {
            form.setFieldsValue({ name: props.openSettings.list ? `${props.openSettings.list.name} (1)` : '' })
        }
    }, [props.openSettings])

    return (
        <>
            <Modal
                closable={false}
                className={'modal'}
                open={open}
                footer={
                    <>
                        <Button
                            disabled={isSaving}
                            className='yellowOutlinedCancelButton'
                            onClick={() => props.onCancel()}>
                            {t('cancel')}
                        </Button>
                        <Button
                            loading={isSaving}
                            className={'yellowFillPositiveButton'}
                            onClick={() => form.submit()}>
                            {list ? t('clone') : t('save')}
                        </Button>
                    </>
                }>
                <Form
                    form={form}
                    initialValues={{ name: props.openSettings.list ? `${props.openSettings.list.name} (1)` : '' }}
                    onFinish={values => handleFinish(values)}
                    className={styles.form}
                    requiredMark={false}
                    layout='vertical'>
                    <p>
                        <Form.Item rules={[{ required: true }]} name={'name'} label={t('name')}>
                            <Input />
                        </Form.Item>
                    </p>
                </Form>
            </Modal>
            <Modal
                closable={false}
                className={'modal'}
                open={operationSuccessful}
                footer={
                    <>
                        <Button
                            disabled={isSaving}
                            className='blackFillPositiveButton'
                            onClick={() => {
                                setOperationSuccessful(false)
                                props.onSave(response!)
                            }}>
                            OK
                        </Button>
                    </>
                }>
                <OkIcon />
                <p>
                    {list ? t('listCloned') : t('listSaved')}
                </p>
            </Modal>
        </>
    )
}