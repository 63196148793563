import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import './App.css'
import { RootState } from './app/store'
import Dashboard from './pages/dashboard/newDashboard/Dashboard'
import { Login } from './pages/login/Login'
import { Main } from './pages/main/Main'
import { NotificationBadge, NotificationWidget } from './pages/notification/NotificationWidget'
import { IS_LOGGED } from './service/model'

export function isUserLogged(): boolean {
    return localStorage.getItem(IS_LOGGED) === 'true'
}

export const isAdminDashboard = (adminDashboardValue = 'false'): boolean => {
    let result = false
    try {
        result = JSON.parse(adminDashboardValue)
    } catch (e) {
        console.log(e)
    }
    return result
}

const App = (): ReactElement => {
    const dashboardState = useSelector((state: RootState) => state.dashboardState)
    const userState = useSelector((state: RootState) => state.currentUserState)
    const [adminDashboard, setAdminDashboard] = useState<boolean>()
    useEffect(() => {
        const adminDashboard = userState.user.application.settings.find(setting => setting.key === 'adminDashboard')
        const dashboardValue: boolean = isAdminDashboard(adminDashboard?.value)
        setAdminDashboard(dashboardValue)
    }, [userState.user])
    return (
        <>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/login' element={<Navigate to='/main' replace />} />
                <Route path='/notification-badge' element={
                    <RequireAuth>
                        <NotificationBadge />
                    </RequireAuth>
                } />
                <Route path='/notification-widget' element={
                    <RequireAuth>
                        <NotificationWidget />
                    </RequireAuth>
                } />
                <Route path='/main/*' element={
                    <RequireAuth>
                        <Main />
                    </RequireAuth>
                } />
                {adminDashboard &&
                    <>
                        <Route path='/dashboard' element={
                            <RequireAuth>
                                <Dashboard />
                            </RequireAuth>
                        } />
                    </>
                }
            </Routes>
        </>
    )
}

function Home() {
    return !isUserLogged() ? <Login /> : <Navigate to='/main' replace />
}

export function RequireAuth({ children }: { children: JSX.Element }) {
    const location = useLocation()
    return !isUserLogged() ? <Navigate to='/' state={{ from: location }} replace /> : children
}

export default App
