import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StockAction } from '../../pages/ecommerce/Inventory/InventoryDetailCard/VariantStocksComponent/WarehouseCardComponent'
import { Batch, InventoryVariant, Location } from '../../service/api'


interface InventoryState {
    variant?: InventoryVariant
    warehouse?: Location
    action?: StockAction
    batch?: Batch
    operationComplete?: boolean
}

const initialState: InventoryState = {}

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        setSelectedWarehouse: (state, action: PayloadAction<Location | undefined>) => {
            state.warehouse = action.payload
        },
        setSelectedAction: (state, action: PayloadAction<StockAction | undefined>) => {
            state.action = action.payload
        },
        setSelectedVariant: (state, action: PayloadAction<InventoryVariant | undefined>) => {
            state.variant = action.payload
        },
        setSelectedBatch: (state, action: PayloadAction<Batch | undefined>) => {
            state.batch = action.payload
        },
        setOperationComplete: (state, action: PayloadAction<boolean | undefined>) => {
            state.operationComplete = action.payload
        }
    }
})

export const { setSelectedWarehouse, setSelectedAction, setSelectedVariant, setSelectedBatch, setOperationComplete } = inventorySlice.actions
export default inventorySlice.reducer