import { Button, Col, Divider, Popover, Row, Tabs, notification } from 'antd'
import Search from 'antd/lib/input/Search'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { ChangeEvent, Key, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ContactIcon } from '../../../../assets/addContact.svg'
import { ReactComponent as SliderIcon } from '../../../../assets/filters.svg'
import searchStyles from '../../../../common/commonCss/Search.module.css'
import commonStyles from '../../../../common/commonCss/Tabs.module.css'
import TitleWithSubtitle from '../../../../common/components/commonUI/TitleWithSubtitle'
import { getFullName, isAppCustomer } from '../../../../common/utils'
import { useMobileMediaQuery } from '../../../../hooks/useMobileMediaQuery'
import { CustomerApiFactory, CustomerDetail } from '../../../../service/api'
import { ContactFilterPopover } from './ContactFilterPopover'
import styles from './Contacts.module.css'
import { ContactsBulkActions } from './ContactsBulkActions'
import ContactsPopupMenu from './ContactsPopupMenu'
import TableView from './TableView'

export enum CustomerType {
    Server = 1,
    Facebook = 2,
    Twitter = 4,
    Season = 8,
    Apple = 16,
    Loyalty = 32,
    Crm = 64
}

enum TabKey {
    All = 'All',
    App = 'App',
    Other = 'Other'
}

const CONTACT_DETAIL_ADDRESS = 'detail'
export interface ContactFilters {
    location?: string,
    startDate?: dayjs.Dayjs,
    endDate?: dayjs.Dayjs
}
const Contacts = () => {
    const isMobile = useMobileMediaQuery()
    const [t] = useTranslation()
    const navigate = useNavigate()
    const api = CustomerApiFactory()
    const [loaded, setLoaded] = useState<boolean>(false)
    const [customers, setCustomers] = useState<CustomerDetail[]>([])
    const [filteredCustomers, setFilteredCustomers] = useState<CustomerDetail[]>([])
    const [isFiltering, setIsFiltering] = useState<boolean>(false)
    const [filters, setFilters] = useState<ContactFilters>()

    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

    const [openFilterPopover, setOpenFilterPopover] = useState<boolean>(false)

    const updateData = () => {
        setLoaded(false)
        setFilteredCustomers([])
        setSelectedRowKeys([])
        api.getCustomers().then(response => {
            const customers: CustomerDetail[] = response.data
            const customerItems = customers.map(customer => {
                const customerItem: CustomerDetail = {
                    pk: customer.pk,
                    email: customer.email,
                    firstName: customer.firstName ?? '',
                    lastName: customer.lastName ?? '',
                    gender: customer.gender,
                    address: customer.address,
                    zip: customer.zip,
                    city: customer.city ?? '',
                    province: customer.province,
                    country: customer.country,
                    phone: customer.phone ?? '',
                    pictureUrl: customer.pictureUrl,
                    profilePushAccepted: customer.profilePushAccepted,
                    deviceCount: customer.deviceCount,
                    type: customer.type
                }
                const birthDate = customer.birthDate ?? null
                if (birthDate !== null) {
                    customerItem.birthDate = dayjs(birthDate).valueOf()
                }
                const registrationDate = customer.registrationDate ?? null
                if (registrationDate !== null) {
                    customerItem.registrationDate = dayjs(registrationDate).valueOf()
                }
                return customerItem
            })
            // starting order is by registration date
            customerItems.sort((a, b) => {
                // FIXME Similar code (with sign changed) as in TableView sorter for the registrationDate column
                return (b.registrationDate ?? 0) - (a.registrationDate ?? 0)
            })
            setCustomers(customerItems)
            setFilteredCustomers(customerItems)

        }).catch((error: AxiosError) => {
            notification.error({
                message: t('error'),
                description: error.name,
            })
        }).finally(() => {
            setLoaded(true)
        })
    }
    useEffect(() => {
        updateData()
    }, [setCustomers])

    const onImportClick = () => {
        const customerEmails = customers.map(customer => customer.email)
        navigate('import', { state: customerEmails })
    }

    const onTabChange = (activeKey: string) => {
        switch (activeKey) {
        case TabKey.All:
            setFilteredCustomers(customers)
            break
        case TabKey.App: {
            setFilteredCustomers(customers.filter(customer => {
                return isAppCustomer(customer.type)
            }))
            break
        }
        case TabKey.Other: {
            setFilteredCustomers(customers.filter(customer => {
                return !isAppCustomer(customer.type)
            }))
            break
        }
        default:
            console.log(`Unknown tab key: ${activeKey}`)
        }
    }

    const handleSortChange = (sortedCustomers: CustomerDetail[]) => {
        setFilteredCustomers(sortedCustomers)
    }

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const searchedContent = event.target.value.toLowerCase()
        setFilteredCustomers(searchedContent.trim().length > 0 ?
            customers.filter(customer =>
                getFullName({ firstName: customer.firstName?.toLowerCase(), lastName: customer.lastName?.toLowerCase() })
                    .includes(searchedContent) || customer.email.toLowerCase().includes(searchedContent) ||
                customer.city?.toLowerCase().includes(searchedContent) || customer.phone?.toLowerCase().includes(searchedContent)
            )
            : customers)
    }

    const handleFilter = (location?: string, startDate?: dayjs.Dayjs, endDate?: dayjs.Dayjs) => {
        setFilters({ location: location, startDate: startDate, endDate: endDate })
        if (!location && !startDate && !endDate) {
            setFilteredCustomers(customers)
            setIsFiltering(false)
        } else {
            setIsFiltering(true)
            if (location && startDate && endDate) {
                setFilteredCustomers(customers.filter(customer => {
                    return filterByLocation(customer, location)
                }).filter(customer => {
                    return filterByDate(customer, startDate, endDate)
                }))
            } else if (location) {
                setFilteredCustomers(customers.filter(customer => {
                    return filterByLocation(customer, location)
                }))
            } else if (startDate && endDate) {
                setFilteredCustomers(customers.filter(customer => {
                    return filterByDate(customer, startDate, endDate)
                }))
            }
        }
        setOpenFilterPopover(false)
    }

    const filterByLocation = (customer: CustomerDetail, location: string) => {
        return location === t('other') ? customer.city === '' : customer.city === location
    }

    const filterByDate = (customer: CustomerDetail, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
        if (customer.registrationDate !== undefined) {
            const registrationDate = dayjs(customer.registrationDate)
            return (registrationDate.isSame(startDate) || registrationDate.isAfter(startDate))
                && (registrationDate.isSame(endDate) || registrationDate.isBefore(endDate))
        }
    }

    const handleRowClick = (selectedContact: CustomerDetail) => {
        const params = {
            selectedContact: selectedContact
        }
        navigate(CONTACT_DETAIL_ADDRESS, { state: params })
    }
    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                {isMobile ?
                    <p className={styles.customerCount}>
                        {customers.length} {(t('contacts') as string).toLowerCase()}
                    </p>
                    :
                    <TitleWithSubtitle
                        title={t('contacts')}
                        items={customers.length}
                        itemName={t('contacts')} />
                }
                <div className={styles.popUpContainer}>
                    <Button
                        shape={isMobile ? 'circle' : 'default'}
                        onClick={() => {
                            navigate('add')
                        }}
                        className={`${isMobile ? styles.addButton : 'yellowFillPositiveButton'} ${styles.defaultButton}`}
                        icon={<ContactIcon fill='black' />}>
                        {!isMobile && t('newContact')}
                    </Button>
                    <ContactsPopupMenu exportData={filteredCustomers} onImportClick={onImportClick} />
                </div>
            </div>
            {isMobile && <Divider className={styles.divider} />}
            <div className={styles.searchContainer}>
                <Search
                    placeholder={t('searchContacts')}
                    className={searchStyles.search}
                    allowClear
                    onChange={handleSearch}
                />
                <Popover
                    content={
                        <ContactFilterPopover
                            filters={filters}
                            locations={Array.from(new Set(customers.map((item) => item.city ? item.city : '')))}
                            data={filteredCustomers}
                            onFilter={handleFilter}
                            onCancel={(location, startDate, endDate) => {
                                setFilters({
                                    location: location,
                                    startDate: startDate,
                                    endDate: endDate
                                })
                                setOpenFilterPopover(false)
                            }} />
                    }
                    trigger='click'
                    open={openFilterPopover}
                    placement='right'
                    onOpenChange={open => setOpenFilterPopover(open)}
                >
                    <Button className={`${styles.filterButton} ${isFiltering && styles.filterButtonActive}`} icon={<SliderIcon />} type='text' />
                </Popover>
            </div>
            <div className={styles.contactsContainer}>
                <Row className={styles.contactsRow}>
                    <Col span={24}>
                        <div className={styles.tabsRow} style={{ visibility: loaded ? 'visible' : 'hidden' }}>
                            <div>
                                <Tabs
                                    className={`${commonStyles.tabs} ${styles.tabList}`}
                                    defaultActiveKey={TabKey.All}
                                    items={[
                                        { key: TabKey.All, label: t('all') },
                                        { key: TabKey.App, label: t('appUsers') },
                                        { key: TabKey.Other, label: t('otherContacts') }
                                    ]}
                                    onChange={onTabChange} />
                            </div>
                            <div>
                                <ContactsBulkActions
                                    onDelete={updateData}
                                    data={filteredCustomers.filter(customer => {
                                        return selectedRowKeys.map(key => key.valueOf()).includes(customer.pk)
                                    })} />
                            </div>
                        </div>
                        <div>
                            <TableView
                                selectedKeys={selectedRowKeys}
                                onRowClick={handleRowClick}
                                onSelect={setSelectedRowKeys}
                                onSortChange={handleSortChange}
                                loaded={loaded}
                                data={filteredCustomers} />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Contacts
