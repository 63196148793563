import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCSVDownloader } from 'react-papaparse'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedAction, setSelectedWarehouse } from '../../../../../app/Reducers/InventorySlice'
import { RootState } from '../../../../../app/store'
import { ReactComponent as ExportIcon } from '../../../../../assets/export.svg'
import { ReactComponent as MoreIcon } from '../../../../../assets/more_vertical.svg'
import { ReactComponent as StockInIcon } from '../../../../../assets/stockIn.svg'
import PopupMenu from '../../../../../common/components/PopupMenu'
import { Batch, InventoryApiFactory, Location } from '../../../../../service/api'
import { StockAction } from './WarehouseCardComponent'
import styles from './WarehousePopover.module.css'

interface WarehousePopoverProps {
    warehouse: Location
    getExportData: (data: Batch[]) => void
}
export const WarehousePopover = ({ warehouse, getExportData }: WarehousePopoverProps): ReactElement => {
    const api = InventoryApiFactory()
    const [t] = useTranslation('translations')
    const [popupVisible, setPopupVisible] = useState<boolean>(false)
    const [data, setData] = useState<Batch[]>([])

    const inventoryState = useSelector((state: RootState) => state.inventoryState)

    const { CSVDownloader } = useCSVDownloader()

    const dispatch = useDispatch()

    const handlePopupItemClicked = () => {
        setPopupVisible(!popupVisible)
    }

    const updateBatches = () => {
        api.getLocationBatches(warehouse.pk!, inventoryState.variant!.pk).then(response => {
            setData(response.data)
        })
    }


    const onClick = (action: StockAction) => {
        dispatch(setSelectedWarehouse(warehouse))
        dispatch(setSelectedAction(action))
        setPopupVisible(false)
    }

    const content = [
        {
            key: 1,
            label: <div className={styles.popoverLabel}><StockInIcon className={styles.popoverIcon} />{t('stockIn')}</div>,
            disabled: false,
            onClick: () => onClick(StockAction.STOCK_IN),
        },
        {
            key: 2,
            label:
                data.length > 0 ?
                    <CSVDownloader
                        filename={warehouse.name}
                        bom={true}
                        data={() => getExportData(data)}>
                        <div className={styles.popoverLabel}><ExportIcon className={styles.popoverIcon} />{t('exportAll')}</div>
                    </CSVDownloader> :
                    <div className={styles.popoverLabel}><ExportIcon className={styles.popoverIcon} />{t('exportAll')}</div>
            ,
            disabled: data.length <= 0,
            onClick: () => setPopupVisible(false)
        }
    ]

    useEffect(() => {
        if (popupVisible) {
            updateBatches()
        }
    }, [popupVisible])
    return (
        <PopupMenu popupVisible={popupVisible} content={content} >
            <div
                className={styles.popupOpenIcon}
                onClick={(e) => {
                    handlePopupItemClicked()
                    e.stopPropagation()
                }}>
                <MoreIcon />
            </div>
        </PopupMenu>
    )
}