import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setOperationComplete } from '../../../../app/Reducers/InventorySlice'
import { RootState } from '../../../../app/store'
import '../../../../common/commonCss/modal.css'
import { InventoryVariant } from '../../../../service/api'
import { StockAction } from '../InventoryDetailCard/VariantStocksComponent/WarehouseCardComponent'
import { StockInModal } from './StockIn/StockInModal'
import { StockMoveModal } from './StockMove/StockMoveModal'
import { StockOutModal } from './StockOut/StockOutModal'

interface InventoryModalManagerProps {
    open: boolean,
    productTitle: string,
    variant: InventoryVariant
    onCancel: () => void
}
export const InventoryModalManager = ({ open, productTitle, variant, onCancel }: InventoryModalManagerProps): ReactElement => {
    const [t] = useTranslation('translations')
    const inventoryState = useSelector((state: RootState) => state.inventoryState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setOperationComplete(false))
    }, [open])

    const handleCancel = () => {
        onCancel()
    }
    const handleComplete = () => {
        dispatch(setOperationComplete(true))
    }
    return (
        <>
            {(inventoryState.action === StockAction.STOCK_IN) &&
                <StockInModal
                    onComplete={handleComplete}
                    onCancel={handleCancel}
                    productTitle={productTitle}
                    variant={variant}
                    open={open} />}
            {(inventoryState.action === StockAction.STOCK_OUT) &&
                <StockOutModal
                    onComplete={handleComplete}
                    onCancel={handleCancel}
                    productTitle={productTitle}
                    variant={variant}
                    open={open} />}
            {(inventoryState.action === StockAction.STOCK_MOVE) &&
                <StockMoveModal
                    onComplete={handleComplete}
                    onCancel={handleCancel}
                    productTitle={productTitle}
                    variant={variant}
                    open={open} />}
        </>
    )
}